import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type ArticleCountBadge = {
   __typename?: 'ArticleCountBadge';
  region?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type ArticleDataResponse = {
   __typename?: 'ArticleDataResponse';
  totalCount?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<ArticleInfo>>>;
};

export type ArticleInfo = {
   __typename?: 'ArticleInfo';
  _id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type ArticleInput = {
  pagination?: Maybe<PaginationInput>;
  filters?: Maybe<Array<Maybe<FilterInput>>>;
};

export type EditInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type FilterInput = {
  fieldName?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Footer = {
   __typename?: 'Footer';
  _id?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type GlobalRatingDataResponse = {
   __typename?: 'GlobalRatingDataResponse';
  totalCount?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<GlobalRatingInfo>>>;
};

export type GlobalRatingInfo = {
   __typename?: 'GlobalRatingInfo';
  _id?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Float']>;
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type LoginInput = {
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type Mutation = {
   __typename?: 'Mutation';
  createRating?: Maybe<RatingInfo>;
  createGlobalRating?: Maybe<RatingInfo>;
  createArticle?: Maybe<ArticleInfo>;
  editRating?: Maybe<RatingInfo>;
  editGlobalRating?: Maybe<RatingInfo>;
  editArticle?: Maybe<ArticleInfo>;
  removeRating?: Maybe<Scalars['Boolean']>;
  removeGlobalRating?: Maybe<Scalars['Boolean']>;
  removeArticle?: Maybe<Scalars['Boolean']>;
  editFooter?: Maybe<Footer>;
  createNews?: Maybe<News>;
  editNews?: Maybe<News>;
  removeNews?: Maybe<Scalars['Boolean']>;
  login?: Maybe<UserInfo>;
  logout?: Maybe<Scalars['Boolean']>;
  createUser?: Maybe<UserInfo>;
  editUser?: Maybe<UserInfo>;
  removeUser?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateRatingArgs = {
  region?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Float']>;
};


export type MutationCreateGlobalRatingArgs = {
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Float']>;
};


export type MutationCreateArticleArgs = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};


export type MutationEditRatingArgs = {
  id?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};


export type MutationEditGlobalRatingArgs = {
  id?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
};


export type MutationEditArticleArgs = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};


export type MutationRemoveRatingArgs = {
  id?: Maybe<Scalars['String']>;
};


export type MutationRemoveGlobalRatingArgs = {
  id?: Maybe<Scalars['String']>;
};


export type MutationRemoveArticleArgs = {
  id?: Maybe<Scalars['String']>;
};


export type MutationEditFooterArgs = {
  id?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};


export type MutationCreateNewsArgs = {
  title?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};


export type MutationEditNewsArgs = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};


export type MutationRemoveNewsArgs = {
  id?: Maybe<Scalars['String']>;
};


export type MutationLoginArgs = {
  payload?: Maybe<LoginInput>;
};


export type MutationCreateUserArgs = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['Int']>;
};


export type MutationEditUserArgs = {
  payload?: Maybe<EditInput>;
};


export type MutationRemoveUserArgs = {
  email?: Maybe<Scalars['String']>;
};

export type News = {
   __typename?: 'News';
  _id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type NewsDataResponse = {
   __typename?: 'NewsDataResponse';
  totalCount?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<News>>>;
};

export type NewsInput = {
  pagination?: Maybe<PaginationInput>;
  filters?: Maybe<Array<Maybe<FilterInput>>>;
};

export type PaginationInput = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type Query = {
   __typename?: 'Query';
  ratings?: Maybe<RatingDataResponse>;
  globalRatings?: Maybe<GlobalRatingDataResponse>;
  articles?: Maybe<ArticleDataResponse>;
  getArticlesCountBadge?: Maybe<ArticleCountBadge>;
  footer?: Maybe<Footer>;
  news?: Maybe<NewsDataResponse>;
  searchNews?: Maybe<Array<Maybe<News>>>;
  getNewsCount?: Maybe<Scalars['Int']>;
  users?: Maybe<Array<Maybe<UserInfo>>>;
};


export type QueryRatingsArgs = {
  payload?: Maybe<RatingInput>;
  fromTable?: Maybe<Scalars['Boolean']>;
};


export type QueryGlobalRatingsArgs = {
  payload?: Maybe<RatingInput>;
  fromTable?: Maybe<Scalars['Boolean']>;
};


export type QueryArticlesArgs = {
  payload?: Maybe<ArticleInput>;
  fromTable?: Maybe<Scalars['Boolean']>;
};


export type QueryGetArticlesCountBadgeArgs = {
  payload?: Maybe<ArticleInput>;
};


export type QueryNewsArgs = {
  payload?: Maybe<NewsInput>;
  fromTable?: Maybe<Scalars['Boolean']>;
};


export type QuerySearchNewsArgs = {
  text?: Maybe<Scalars['String']>;
};

export type RatingDataResponse = {
   __typename?: 'RatingDataResponse';
  totalCount?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<RatingInfo>>>;
};

export type RatingInfo = {
   __typename?: 'RatingInfo';
  _id?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Float']>;
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type RatingInput = {
  pagination?: Maybe<PaginationInput>;
  filters?: Maybe<Array<Maybe<FilterInput>>>;
};

export type UserInfo = {
   __typename?: 'UserInfo';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type GetRatingsQueryVariables = {
  payload?: Maybe<RatingInput>;
  fromTable?: Maybe<Scalars['Boolean']>;
};


export type GetRatingsQuery = (
  { __typename?: 'Query' }
  & { ratings?: Maybe<(
    { __typename?: 'RatingDataResponse' }
    & Pick<RatingDataResponse, 'totalCount'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'RatingInfo' }
      & Pick<RatingInfo, '_id' | 'region' | 'month' | 'year' | 'rating' | 'created_at' | 'updated_at'>
    )>>> }
  )> }
);

export type GetGlobalRatingsQueryVariables = {
  payload?: Maybe<RatingInput>;
  fromTable?: Maybe<Scalars['Boolean']>;
};


export type GetGlobalRatingsQuery = (
  { __typename?: 'Query' }
  & { globalRatings?: Maybe<(
    { __typename?: 'GlobalRatingDataResponse' }
    & Pick<GlobalRatingDataResponse, 'totalCount'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'GlobalRatingInfo' }
      & Pick<GlobalRatingInfo, '_id' | 'month' | 'year' | 'rating' | 'created_at' | 'updated_at'>
    )>>> }
  )> }
);

export type GetRatingsShortQueryVariables = {
  payload?: Maybe<RatingInput>;
};


export type GetRatingsShortQuery = (
  { __typename?: 'Query' }
  & { ratings?: Maybe<(
    { __typename?: 'RatingDataResponse' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'RatingInfo' }
      & Pick<RatingInfo, 'region' | 'rating'>
    )>>> }
  )> }
);

export type GetGlobalRatingsShortQueryVariables = {
  payload?: Maybe<RatingInput>;
};


export type GetGlobalRatingsShortQuery = (
  { __typename?: 'Query' }
  & { globalRatings?: Maybe<(
    { __typename?: 'GlobalRatingDataResponse' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'GlobalRatingInfo' }
      & Pick<GlobalRatingInfo, 'rating'>
    )>>> }
  )> }
);

export type GetArticlesQueryVariables = {
  payload?: Maybe<ArticleInput>;
  fromTable?: Maybe<Scalars['Boolean']>;
};


export type GetArticlesQuery = (
  { __typename?: 'Query' }
  & { articles?: Maybe<(
    { __typename?: 'ArticleDataResponse' }
    & Pick<ArticleDataResponse, 'totalCount'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'ArticleInfo' }
      & Pick<ArticleInfo, '_id' | 'title' | 'type' | 'region' | 'month' | 'year' | 'created_at' | 'updated_at'>
    )>>> }
  )> }
);

export type GetArticlesShortQueryVariables = {
  payload?: Maybe<ArticleInput>;
};


export type GetArticlesShortQuery = (
  { __typename?: 'Query' }
  & { articles?: Maybe<(
    { __typename?: 'ArticleDataResponse' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'ArticleInfo' }
      & Pick<ArticleInfo, '_id' | 'title' | 'type'>
    )>>> }
  )> }
);

export type GetArticlesFullQueryVariables = {
  payload?: Maybe<ArticleInput>;
};


export type GetArticlesFullQuery = (
  { __typename?: 'Query' }
  & { articles?: Maybe<(
    { __typename?: 'ArticleDataResponse' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'ArticleInfo' }
      & Pick<ArticleInfo, '_id' | 'title' | 'description' | 'type' | 'region' | 'month' | 'year' | 'created_at' | 'updated_at'>
    )>>> }
  )> }
);

export type GetArticlesEditQueryVariables = {
  payload?: Maybe<ArticleInput>;
};


export type GetArticlesEditQuery = (
  { __typename?: 'Query' }
  & { articles?: Maybe<(
    { __typename?: 'ArticleDataResponse' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'ArticleInfo' }
      & Pick<ArticleInfo, '_id' | 'title' | 'description' | 'region' | 'month' | 'year' | 'type'>
    )>>> }
  )> }
);

export type GetArticlesCountBadgeQueryVariables = {
  payload?: Maybe<ArticleInput>;
};


export type GetArticlesCountBadgeQuery = (
  { __typename?: 'Query' }
  & { getArticlesCountBadge?: Maybe<(
    { __typename?: 'ArticleCountBadge' }
    & Pick<ArticleCountBadge, 'region' | 'count'>
  )> }
);

export type GetNewsQueryVariables = {
  payload?: Maybe<NewsInput>;
  fromTable?: Maybe<Scalars['Boolean']>;
};


export type GetNewsQuery = (
  { __typename?: 'Query' }
  & { news?: Maybe<(
    { __typename?: 'NewsDataResponse' }
    & Pick<NewsDataResponse, 'totalCount'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'News' }
      & Pick<News, '_id' | 'title' | 'month' | 'year' | 'created_at' | 'updated_at'>
    )>>> }
  )> }
);

export type GetNewsListQueryVariables = {
  payload?: Maybe<NewsInput>;
  fromTable?: Maybe<Scalars['Boolean']>;
};


export type GetNewsListQuery = (
  { __typename?: 'Query' }
  & { news?: Maybe<(
    { __typename?: 'NewsDataResponse' }
    & Pick<NewsDataResponse, 'totalCount'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'News' }
      & Pick<News, '_id' | 'title' | 'month' | 'year' | 'shortDescription' | 'created_at' | 'updated_at'>
    )>>> }
  )> }
);

export type SearchNewsQueryVariables = {
  text?: Maybe<Scalars['String']>;
};


export type SearchNewsQuery = (
  { __typename?: 'Query' }
  & { searchNews?: Maybe<Array<Maybe<(
    { __typename?: 'News' }
    & Pick<News, '_id' | 'title'>
  )>>> }
);

export type GetNewsShortContentQueryVariables = {
  payload?: Maybe<NewsInput>;
};


export type GetNewsShortContentQuery = (
  { __typename?: 'Query' }
  & { news?: Maybe<(
    { __typename?: 'NewsDataResponse' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'News' }
      & Pick<News, '_id' | 'title' | 'shortDescription'>
    )>>> }
  )> }
);

export type GetNewsFullContentQueryVariables = {
  payload?: Maybe<NewsInput>;
};


export type GetNewsFullContentQuery = (
  { __typename?: 'Query' }
  & { news?: Maybe<(
    { __typename?: 'NewsDataResponse' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'News' }
      & Pick<News, '_id' | 'title' | 'description' | 'created_at'>
    )>>> }
  )> }
);

export type GetNewsEditQueryVariables = {
  payload?: Maybe<NewsInput>;
};


export type GetNewsEditQuery = (
  { __typename?: 'Query' }
  & { news?: Maybe<(
    { __typename?: 'NewsDataResponse' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'News' }
      & Pick<News, '_id' | 'title' | 'shortDescription' | 'description' | 'year' | 'month'>
    )>>> }
  )> }
);

export type CreateRatingMutationVariables = {
  region?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Float']>;
};


export type CreateRatingMutation = (
  { __typename?: 'Mutation' }
  & { createRating?: Maybe<(
    { __typename?: 'RatingInfo' }
    & Pick<RatingInfo, '_id' | 'region' | 'month' | 'year' | 'rating' | 'created_at' | 'updated_at'>
  )> }
);

export type CreateGlobalRatingMutationVariables = {
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Float']>;
};


export type CreateGlobalRatingMutation = (
  { __typename?: 'Mutation' }
  & { createGlobalRating?: Maybe<(
    { __typename?: 'RatingInfo' }
    & Pick<RatingInfo, '_id' | 'month' | 'year' | 'rating' | 'created_at' | 'updated_at'>
  )> }
);

export type EditRatingMutationVariables = {
  id?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
};


export type EditRatingMutation = (
  { __typename?: 'Mutation' }
  & { editRating?: Maybe<(
    { __typename?: 'RatingInfo' }
    & Pick<RatingInfo, '_id' | 'region' | 'month' | 'year' | 'rating' | 'created_at' | 'updated_at'>
  )> }
);

export type EditGlobalRatingMutationVariables = {
  id?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
};


export type EditGlobalRatingMutation = (
  { __typename?: 'Mutation' }
  & { editGlobalRating?: Maybe<(
    { __typename?: 'RatingInfo' }
    & Pick<RatingInfo, '_id' | 'month' | 'year' | 'rating' | 'created_at' | 'updated_at'>
  )> }
);

export type RemoveRatingMutationVariables = {
  id?: Maybe<Scalars['String']>;
};


export type RemoveRatingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeRating'>
);

export type RemoveGlobalRatingMutationVariables = {
  id?: Maybe<Scalars['String']>;
};


export type RemoveGlobalRatingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeGlobalRating'>
);

export type CreateArticleMutationVariables = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};


export type CreateArticleMutation = (
  { __typename?: 'Mutation' }
  & { createArticle?: Maybe<(
    { __typename?: 'ArticleInfo' }
    & Pick<ArticleInfo, '_id' | 'title' | 'type' | 'region' | 'month' | 'year' | 'created_at' | 'updated_at'>
  )> }
);

export type EditArticleMutationVariables = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};


export type EditArticleMutation = (
  { __typename?: 'Mutation' }
  & { editArticle?: Maybe<(
    { __typename?: 'ArticleInfo' }
    & Pick<ArticleInfo, '_id' | 'title' | 'type' | 'region' | 'month' | 'year' | 'created_at' | 'updated_at'>
  )> }
);

export type RemoveArticleMutationVariables = {
  id?: Maybe<Scalars['String']>;
};


export type RemoveArticleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeArticle'>
);

export type CreateNewsMutationVariables = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};


export type CreateNewsMutation = (
  { __typename?: 'Mutation' }
  & { createNews?: Maybe<(
    { __typename?: 'News' }
    & Pick<News, '_id' | 'title' | 'month' | 'year' | 'created_at' | 'updated_at'>
  )> }
);

export type EditNewsMutationVariables = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};


export type EditNewsMutation = (
  { __typename?: 'Mutation' }
  & { editNews?: Maybe<(
    { __typename?: 'News' }
    & Pick<News, '_id' | 'title' | 'month' | 'year' | 'created_at' | 'updated_at'>
  )> }
);

export type RemoveNewsMutationVariables = {
  id?: Maybe<Scalars['String']>;
};


export type RemoveNewsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeNews'>
);

export type GetFooterQueryVariables = {};


export type GetFooterQuery = (
  { __typename?: 'Query' }
  & { footer?: Maybe<(
    { __typename?: 'Footer' }
    & Pick<Footer, '_id' | 'content'>
  )> }
);

export type EditFooterMutationVariables = {
  id?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};


export type EditFooterMutation = (
  { __typename?: 'Mutation' }
  & { editFooter?: Maybe<(
    { __typename?: 'Footer' }
    & Pick<Footer, '_id' | 'content'>
  )> }
);

export type GetUsersQueryVariables = {};


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<Array<Maybe<(
    { __typename?: 'UserInfo' }
    & Pick<UserInfo, 'name' | 'email'>
  )>>> }
);

export type LoginMutationVariables = {
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'UserInfo' }
    & Pick<UserInfo, 'email' | 'name'>
  )> }
);

export type CreateUserMutationVariables = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser?: Maybe<(
    { __typename?: 'UserInfo' }
    & Pick<UserInfo, 'name' | 'email'>
  )> }
);

export type RemoveUserMutationVariables = {
  email?: Maybe<Scalars['String']>;
};


export type RemoveUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeUser'>
);

export type EditUserMutationVariables = {
  payload?: Maybe<EditInput>;
};


export type EditUserMutation = (
  { __typename?: 'Mutation' }
  & { editUser?: Maybe<(
    { __typename?: 'UserInfo' }
    & Pick<UserInfo, 'name'>
  )> }
);

export type LogoutMutationVariables = {};


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);


export const GetRatingsDocument = gql`
    query GetRatings($payload: RatingInput, $fromTable: Boolean) {
  ratings(payload: $payload, fromTable: $fromTable) {
    totalCount
    data {
      _id
      region
      month
      year
      rating
      created_at
      updated_at
    }
  }
}
    `;
export type GetRatingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetRatingsQuery, GetRatingsQueryVariables>, 'query'>;

    export const GetRatingsComponent = (props: GetRatingsComponentProps) => (
      <ApolloReactComponents.Query<GetRatingsQuery, GetRatingsQueryVariables> query={GetRatingsDocument} {...props} />
    );
    
export type GetRatingsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetRatingsQuery, GetRatingsQueryVariables> & TChildProps;
export function withGetRatings<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetRatingsQuery,
  GetRatingsQueryVariables,
  GetRatingsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetRatingsQuery, GetRatingsQueryVariables, GetRatingsProps<TChildProps>>(GetRatingsDocument, {
      alias: 'getRatings',
      ...operationOptions
    });
};

/**
 * __useGetRatingsQuery__
 *
 * To run a query within a React component, call `useGetRatingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRatingsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRatingsQuery({
 *   variables: {
 *      payload: // value for 'payload'
 *      fromTable: // value for 'fromTable'
 *   },
 * });
 */
export function useGetRatingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRatingsQuery, GetRatingsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRatingsQuery, GetRatingsQueryVariables>(GetRatingsDocument, baseOptions);
      }
export function useGetRatingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRatingsQuery, GetRatingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRatingsQuery, GetRatingsQueryVariables>(GetRatingsDocument, baseOptions);
        }
export type GetRatingsQueryHookResult = ReturnType<typeof useGetRatingsQuery>;
export type GetRatingsLazyQueryHookResult = ReturnType<typeof useGetRatingsLazyQuery>;
export type GetRatingsQueryResult = ApolloReactCommon.QueryResult<GetRatingsQuery, GetRatingsQueryVariables>;
export const GetGlobalRatingsDocument = gql`
    query GetGlobalRatings($payload: RatingInput, $fromTable: Boolean) {
  globalRatings(payload: $payload, fromTable: $fromTable) {
    totalCount
    data {
      _id
      month
      year
      rating
      created_at
      updated_at
    }
  }
}
    `;
export type GetGlobalRatingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetGlobalRatingsQuery, GetGlobalRatingsQueryVariables>, 'query'>;

    export const GetGlobalRatingsComponent = (props: GetGlobalRatingsComponentProps) => (
      <ApolloReactComponents.Query<GetGlobalRatingsQuery, GetGlobalRatingsQueryVariables> query={GetGlobalRatingsDocument} {...props} />
    );
    
export type GetGlobalRatingsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetGlobalRatingsQuery, GetGlobalRatingsQueryVariables> & TChildProps;
export function withGetGlobalRatings<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetGlobalRatingsQuery,
  GetGlobalRatingsQueryVariables,
  GetGlobalRatingsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetGlobalRatingsQuery, GetGlobalRatingsQueryVariables, GetGlobalRatingsProps<TChildProps>>(GetGlobalRatingsDocument, {
      alias: 'getGlobalRatings',
      ...operationOptions
    });
};

/**
 * __useGetGlobalRatingsQuery__
 *
 * To run a query within a React component, call `useGetGlobalRatingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlobalRatingsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlobalRatingsQuery({
 *   variables: {
 *      payload: // value for 'payload'
 *      fromTable: // value for 'fromTable'
 *   },
 * });
 */
export function useGetGlobalRatingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGlobalRatingsQuery, GetGlobalRatingsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGlobalRatingsQuery, GetGlobalRatingsQueryVariables>(GetGlobalRatingsDocument, baseOptions);
      }
export function useGetGlobalRatingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGlobalRatingsQuery, GetGlobalRatingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGlobalRatingsQuery, GetGlobalRatingsQueryVariables>(GetGlobalRatingsDocument, baseOptions);
        }
export type GetGlobalRatingsQueryHookResult = ReturnType<typeof useGetGlobalRatingsQuery>;
export type GetGlobalRatingsLazyQueryHookResult = ReturnType<typeof useGetGlobalRatingsLazyQuery>;
export type GetGlobalRatingsQueryResult = ApolloReactCommon.QueryResult<GetGlobalRatingsQuery, GetGlobalRatingsQueryVariables>;
export const GetRatingsShortDocument = gql`
    query GetRatingsShort($payload: RatingInput) {
  ratings(payload: $payload) {
    data {
      region
      rating
    }
  }
}
    `;
export type GetRatingsShortComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetRatingsShortQuery, GetRatingsShortQueryVariables>, 'query'>;

    export const GetRatingsShortComponent = (props: GetRatingsShortComponentProps) => (
      <ApolloReactComponents.Query<GetRatingsShortQuery, GetRatingsShortQueryVariables> query={GetRatingsShortDocument} {...props} />
    );
    
export type GetRatingsShortProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetRatingsShortQuery, GetRatingsShortQueryVariables> & TChildProps;
export function withGetRatingsShort<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetRatingsShortQuery,
  GetRatingsShortQueryVariables,
  GetRatingsShortProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetRatingsShortQuery, GetRatingsShortQueryVariables, GetRatingsShortProps<TChildProps>>(GetRatingsShortDocument, {
      alias: 'getRatingsShort',
      ...operationOptions
    });
};

/**
 * __useGetRatingsShortQuery__
 *
 * To run a query within a React component, call `useGetRatingsShortQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRatingsShortQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRatingsShortQuery({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useGetRatingsShortQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRatingsShortQuery, GetRatingsShortQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRatingsShortQuery, GetRatingsShortQueryVariables>(GetRatingsShortDocument, baseOptions);
      }
export function useGetRatingsShortLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRatingsShortQuery, GetRatingsShortQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRatingsShortQuery, GetRatingsShortQueryVariables>(GetRatingsShortDocument, baseOptions);
        }
export type GetRatingsShortQueryHookResult = ReturnType<typeof useGetRatingsShortQuery>;
export type GetRatingsShortLazyQueryHookResult = ReturnType<typeof useGetRatingsShortLazyQuery>;
export type GetRatingsShortQueryResult = ApolloReactCommon.QueryResult<GetRatingsShortQuery, GetRatingsShortQueryVariables>;
export const GetGlobalRatingsShortDocument = gql`
    query GetGlobalRatingsShort($payload: RatingInput) {
  globalRatings(payload: $payload) {
    data {
      rating
    }
  }
}
    `;
export type GetGlobalRatingsShortComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetGlobalRatingsShortQuery, GetGlobalRatingsShortQueryVariables>, 'query'>;

    export const GetGlobalRatingsShortComponent = (props: GetGlobalRatingsShortComponentProps) => (
      <ApolloReactComponents.Query<GetGlobalRatingsShortQuery, GetGlobalRatingsShortQueryVariables> query={GetGlobalRatingsShortDocument} {...props} />
    );
    
export type GetGlobalRatingsShortProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetGlobalRatingsShortQuery, GetGlobalRatingsShortQueryVariables> & TChildProps;
export function withGetGlobalRatingsShort<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetGlobalRatingsShortQuery,
  GetGlobalRatingsShortQueryVariables,
  GetGlobalRatingsShortProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetGlobalRatingsShortQuery, GetGlobalRatingsShortQueryVariables, GetGlobalRatingsShortProps<TChildProps>>(GetGlobalRatingsShortDocument, {
      alias: 'getGlobalRatingsShort',
      ...operationOptions
    });
};

/**
 * __useGetGlobalRatingsShortQuery__
 *
 * To run a query within a React component, call `useGetGlobalRatingsShortQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlobalRatingsShortQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlobalRatingsShortQuery({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useGetGlobalRatingsShortQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGlobalRatingsShortQuery, GetGlobalRatingsShortQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGlobalRatingsShortQuery, GetGlobalRatingsShortQueryVariables>(GetGlobalRatingsShortDocument, baseOptions);
      }
export function useGetGlobalRatingsShortLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGlobalRatingsShortQuery, GetGlobalRatingsShortQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGlobalRatingsShortQuery, GetGlobalRatingsShortQueryVariables>(GetGlobalRatingsShortDocument, baseOptions);
        }
export type GetGlobalRatingsShortQueryHookResult = ReturnType<typeof useGetGlobalRatingsShortQuery>;
export type GetGlobalRatingsShortLazyQueryHookResult = ReturnType<typeof useGetGlobalRatingsShortLazyQuery>;
export type GetGlobalRatingsShortQueryResult = ApolloReactCommon.QueryResult<GetGlobalRatingsShortQuery, GetGlobalRatingsShortQueryVariables>;
export const GetArticlesDocument = gql`
    query GetArticles($payload: ArticleInput, $fromTable: Boolean) {
  articles(payload: $payload, fromTable: $fromTable) {
    totalCount
    data {
      _id
      title
      type
      region
      month
      year
      created_at
      updated_at
    }
  }
}
    `;
export type GetArticlesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetArticlesQuery, GetArticlesQueryVariables>, 'query'>;

    export const GetArticlesComponent = (props: GetArticlesComponentProps) => (
      <ApolloReactComponents.Query<GetArticlesQuery, GetArticlesQueryVariables> query={GetArticlesDocument} {...props} />
    );
    
export type GetArticlesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetArticlesQuery, GetArticlesQueryVariables> & TChildProps;
export function withGetArticles<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetArticlesQuery,
  GetArticlesQueryVariables,
  GetArticlesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetArticlesQuery, GetArticlesQueryVariables, GetArticlesProps<TChildProps>>(GetArticlesDocument, {
      alias: 'getArticles',
      ...operationOptions
    });
};

/**
 * __useGetArticlesQuery__
 *
 * To run a query within a React component, call `useGetArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticlesQuery({
 *   variables: {
 *      payload: // value for 'payload'
 *      fromTable: // value for 'fromTable'
 *   },
 * });
 */
export function useGetArticlesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArticlesQuery, GetArticlesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArticlesQuery, GetArticlesQueryVariables>(GetArticlesDocument, baseOptions);
      }
export function useGetArticlesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArticlesQuery, GetArticlesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArticlesQuery, GetArticlesQueryVariables>(GetArticlesDocument, baseOptions);
        }
export type GetArticlesQueryHookResult = ReturnType<typeof useGetArticlesQuery>;
export type GetArticlesLazyQueryHookResult = ReturnType<typeof useGetArticlesLazyQuery>;
export type GetArticlesQueryResult = ApolloReactCommon.QueryResult<GetArticlesQuery, GetArticlesQueryVariables>;
export const GetArticlesShortDocument = gql`
    query GetArticlesShort($payload: ArticleInput) {
  articles(payload: $payload) {
    data {
      _id
      title
      type
    }
  }
}
    `;
export type GetArticlesShortComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetArticlesShortQuery, GetArticlesShortQueryVariables>, 'query'>;

    export const GetArticlesShortComponent = (props: GetArticlesShortComponentProps) => (
      <ApolloReactComponents.Query<GetArticlesShortQuery, GetArticlesShortQueryVariables> query={GetArticlesShortDocument} {...props} />
    );
    
export type GetArticlesShortProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetArticlesShortQuery, GetArticlesShortQueryVariables> & TChildProps;
export function withGetArticlesShort<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetArticlesShortQuery,
  GetArticlesShortQueryVariables,
  GetArticlesShortProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetArticlesShortQuery, GetArticlesShortQueryVariables, GetArticlesShortProps<TChildProps>>(GetArticlesShortDocument, {
      alias: 'getArticlesShort',
      ...operationOptions
    });
};

/**
 * __useGetArticlesShortQuery__
 *
 * To run a query within a React component, call `useGetArticlesShortQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticlesShortQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticlesShortQuery({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useGetArticlesShortQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArticlesShortQuery, GetArticlesShortQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArticlesShortQuery, GetArticlesShortQueryVariables>(GetArticlesShortDocument, baseOptions);
      }
export function useGetArticlesShortLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArticlesShortQuery, GetArticlesShortQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArticlesShortQuery, GetArticlesShortQueryVariables>(GetArticlesShortDocument, baseOptions);
        }
export type GetArticlesShortQueryHookResult = ReturnType<typeof useGetArticlesShortQuery>;
export type GetArticlesShortLazyQueryHookResult = ReturnType<typeof useGetArticlesShortLazyQuery>;
export type GetArticlesShortQueryResult = ApolloReactCommon.QueryResult<GetArticlesShortQuery, GetArticlesShortQueryVariables>;
export const GetArticlesFullDocument = gql`
    query GetArticlesFull($payload: ArticleInput) {
  articles(payload: $payload) {
    data {
      _id
      title
      description
      type
      region
      month
      year
      created_at
      updated_at
    }
  }
}
    `;
export type GetArticlesFullComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetArticlesFullQuery, GetArticlesFullQueryVariables>, 'query'>;

    export const GetArticlesFullComponent = (props: GetArticlesFullComponentProps) => (
      <ApolloReactComponents.Query<GetArticlesFullQuery, GetArticlesFullQueryVariables> query={GetArticlesFullDocument} {...props} />
    );
    
export type GetArticlesFullProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetArticlesFullQuery, GetArticlesFullQueryVariables> & TChildProps;
export function withGetArticlesFull<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetArticlesFullQuery,
  GetArticlesFullQueryVariables,
  GetArticlesFullProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetArticlesFullQuery, GetArticlesFullQueryVariables, GetArticlesFullProps<TChildProps>>(GetArticlesFullDocument, {
      alias: 'getArticlesFull',
      ...operationOptions
    });
};

/**
 * __useGetArticlesFullQuery__
 *
 * To run a query within a React component, call `useGetArticlesFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticlesFullQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticlesFullQuery({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useGetArticlesFullQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArticlesFullQuery, GetArticlesFullQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArticlesFullQuery, GetArticlesFullQueryVariables>(GetArticlesFullDocument, baseOptions);
      }
export function useGetArticlesFullLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArticlesFullQuery, GetArticlesFullQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArticlesFullQuery, GetArticlesFullQueryVariables>(GetArticlesFullDocument, baseOptions);
        }
export type GetArticlesFullQueryHookResult = ReturnType<typeof useGetArticlesFullQuery>;
export type GetArticlesFullLazyQueryHookResult = ReturnType<typeof useGetArticlesFullLazyQuery>;
export type GetArticlesFullQueryResult = ApolloReactCommon.QueryResult<GetArticlesFullQuery, GetArticlesFullQueryVariables>;
export const GetArticlesEditDocument = gql`
    query GetArticlesEdit($payload: ArticleInput) {
  articles(payload: $payload) {
    data {
      _id
      title
      description
      region
      month
      year
      type
    }
  }
}
    `;
export type GetArticlesEditComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetArticlesEditQuery, GetArticlesEditQueryVariables>, 'query'>;

    export const GetArticlesEditComponent = (props: GetArticlesEditComponentProps) => (
      <ApolloReactComponents.Query<GetArticlesEditQuery, GetArticlesEditQueryVariables> query={GetArticlesEditDocument} {...props} />
    );
    
export type GetArticlesEditProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetArticlesEditQuery, GetArticlesEditQueryVariables> & TChildProps;
export function withGetArticlesEdit<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetArticlesEditQuery,
  GetArticlesEditQueryVariables,
  GetArticlesEditProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetArticlesEditQuery, GetArticlesEditQueryVariables, GetArticlesEditProps<TChildProps>>(GetArticlesEditDocument, {
      alias: 'getArticlesEdit',
      ...operationOptions
    });
};

/**
 * __useGetArticlesEditQuery__
 *
 * To run a query within a React component, call `useGetArticlesEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticlesEditQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticlesEditQuery({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useGetArticlesEditQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArticlesEditQuery, GetArticlesEditQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArticlesEditQuery, GetArticlesEditQueryVariables>(GetArticlesEditDocument, baseOptions);
      }
export function useGetArticlesEditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArticlesEditQuery, GetArticlesEditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArticlesEditQuery, GetArticlesEditQueryVariables>(GetArticlesEditDocument, baseOptions);
        }
export type GetArticlesEditQueryHookResult = ReturnType<typeof useGetArticlesEditQuery>;
export type GetArticlesEditLazyQueryHookResult = ReturnType<typeof useGetArticlesEditLazyQuery>;
export type GetArticlesEditQueryResult = ApolloReactCommon.QueryResult<GetArticlesEditQuery, GetArticlesEditQueryVariables>;
export const GetArticlesCountBadgeDocument = gql`
    query GetArticlesCountBadge($payload: ArticleInput) {
  getArticlesCountBadge(payload: $payload) {
    region
    count
  }
}
    `;
export type GetArticlesCountBadgeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetArticlesCountBadgeQuery, GetArticlesCountBadgeQueryVariables>, 'query'>;

    export const GetArticlesCountBadgeComponent = (props: GetArticlesCountBadgeComponentProps) => (
      <ApolloReactComponents.Query<GetArticlesCountBadgeQuery, GetArticlesCountBadgeQueryVariables> query={GetArticlesCountBadgeDocument} {...props} />
    );
    
export type GetArticlesCountBadgeProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetArticlesCountBadgeQuery, GetArticlesCountBadgeQueryVariables> & TChildProps;
export function withGetArticlesCountBadge<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetArticlesCountBadgeQuery,
  GetArticlesCountBadgeQueryVariables,
  GetArticlesCountBadgeProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetArticlesCountBadgeQuery, GetArticlesCountBadgeQueryVariables, GetArticlesCountBadgeProps<TChildProps>>(GetArticlesCountBadgeDocument, {
      alias: 'getArticlesCountBadge',
      ...operationOptions
    });
};

/**
 * __useGetArticlesCountBadgeQuery__
 *
 * To run a query within a React component, call `useGetArticlesCountBadgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticlesCountBadgeQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticlesCountBadgeQuery({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useGetArticlesCountBadgeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArticlesCountBadgeQuery, GetArticlesCountBadgeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArticlesCountBadgeQuery, GetArticlesCountBadgeQueryVariables>(GetArticlesCountBadgeDocument, baseOptions);
      }
export function useGetArticlesCountBadgeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArticlesCountBadgeQuery, GetArticlesCountBadgeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArticlesCountBadgeQuery, GetArticlesCountBadgeQueryVariables>(GetArticlesCountBadgeDocument, baseOptions);
        }
export type GetArticlesCountBadgeQueryHookResult = ReturnType<typeof useGetArticlesCountBadgeQuery>;
export type GetArticlesCountBadgeLazyQueryHookResult = ReturnType<typeof useGetArticlesCountBadgeLazyQuery>;
export type GetArticlesCountBadgeQueryResult = ApolloReactCommon.QueryResult<GetArticlesCountBadgeQuery, GetArticlesCountBadgeQueryVariables>;
export const GetNewsDocument = gql`
    query GetNews($payload: NewsInput, $fromTable: Boolean) {
  news(payload: $payload, fromTable: $fromTable) {
    totalCount
    data {
      _id
      title
      month
      year
      created_at
      updated_at
    }
  }
}
    `;
export type GetNewsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetNewsQuery, GetNewsQueryVariables>, 'query'>;

    export const GetNewsComponent = (props: GetNewsComponentProps) => (
      <ApolloReactComponents.Query<GetNewsQuery, GetNewsQueryVariables> query={GetNewsDocument} {...props} />
    );
    
export type GetNewsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetNewsQuery, GetNewsQueryVariables> & TChildProps;
export function withGetNews<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetNewsQuery,
  GetNewsQueryVariables,
  GetNewsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetNewsQuery, GetNewsQueryVariables, GetNewsProps<TChildProps>>(GetNewsDocument, {
      alias: 'getNews',
      ...operationOptions
    });
};

/**
 * __useGetNewsQuery__
 *
 * To run a query within a React component, call `useGetNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsQuery({
 *   variables: {
 *      payload: // value for 'payload'
 *      fromTable: // value for 'fromTable'
 *   },
 * });
 */
export function useGetNewsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNewsQuery, GetNewsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNewsQuery, GetNewsQueryVariables>(GetNewsDocument, baseOptions);
      }
export function useGetNewsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNewsQuery, GetNewsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNewsQuery, GetNewsQueryVariables>(GetNewsDocument, baseOptions);
        }
export type GetNewsQueryHookResult = ReturnType<typeof useGetNewsQuery>;
export type GetNewsLazyQueryHookResult = ReturnType<typeof useGetNewsLazyQuery>;
export type GetNewsQueryResult = ApolloReactCommon.QueryResult<GetNewsQuery, GetNewsQueryVariables>;
export const GetNewsListDocument = gql`
    query GetNewsList($payload: NewsInput, $fromTable: Boolean) {
  news(payload: $payload, fromTable: $fromTable) {
    totalCount
    data {
      _id
      title
      month
      year
      shortDescription
      created_at
      updated_at
    }
  }
}
    `;
export type GetNewsListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetNewsListQuery, GetNewsListQueryVariables>, 'query'>;

    export const GetNewsListComponent = (props: GetNewsListComponentProps) => (
      <ApolloReactComponents.Query<GetNewsListQuery, GetNewsListQueryVariables> query={GetNewsListDocument} {...props} />
    );
    
export type GetNewsListProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetNewsListQuery, GetNewsListQueryVariables> & TChildProps;
export function withGetNewsList<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetNewsListQuery,
  GetNewsListQueryVariables,
  GetNewsListProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetNewsListQuery, GetNewsListQueryVariables, GetNewsListProps<TChildProps>>(GetNewsListDocument, {
      alias: 'getNewsList',
      ...operationOptions
    });
};

/**
 * __useGetNewsListQuery__
 *
 * To run a query within a React component, call `useGetNewsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsListQuery({
 *   variables: {
 *      payload: // value for 'payload'
 *      fromTable: // value for 'fromTable'
 *   },
 * });
 */
export function useGetNewsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNewsListQuery, GetNewsListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNewsListQuery, GetNewsListQueryVariables>(GetNewsListDocument, baseOptions);
      }
export function useGetNewsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNewsListQuery, GetNewsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNewsListQuery, GetNewsListQueryVariables>(GetNewsListDocument, baseOptions);
        }
export type GetNewsListQueryHookResult = ReturnType<typeof useGetNewsListQuery>;
export type GetNewsListLazyQueryHookResult = ReturnType<typeof useGetNewsListLazyQuery>;
export type GetNewsListQueryResult = ApolloReactCommon.QueryResult<GetNewsListQuery, GetNewsListQueryVariables>;
export const SearchNewsDocument = gql`
    query SearchNews($text: String) {
  searchNews(text: $text) {
    _id
    title
  }
}
    `;
export type SearchNewsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SearchNewsQuery, SearchNewsQueryVariables>, 'query'>;

    export const SearchNewsComponent = (props: SearchNewsComponentProps) => (
      <ApolloReactComponents.Query<SearchNewsQuery, SearchNewsQueryVariables> query={SearchNewsDocument} {...props} />
    );
    
export type SearchNewsProps<TChildProps = {}> = ApolloReactHoc.DataProps<SearchNewsQuery, SearchNewsQueryVariables> & TChildProps;
export function withSearchNews<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SearchNewsQuery,
  SearchNewsQueryVariables,
  SearchNewsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, SearchNewsQuery, SearchNewsQueryVariables, SearchNewsProps<TChildProps>>(SearchNewsDocument, {
      alias: 'searchNews',
      ...operationOptions
    });
};

/**
 * __useSearchNewsQuery__
 *
 * To run a query within a React component, call `useSearchNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchNewsQuery({
 *   variables: {
 *      text: // value for 'text'
 *   },
 * });
 */
export function useSearchNewsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchNewsQuery, SearchNewsQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchNewsQuery, SearchNewsQueryVariables>(SearchNewsDocument, baseOptions);
      }
export function useSearchNewsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchNewsQuery, SearchNewsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchNewsQuery, SearchNewsQueryVariables>(SearchNewsDocument, baseOptions);
        }
export type SearchNewsQueryHookResult = ReturnType<typeof useSearchNewsQuery>;
export type SearchNewsLazyQueryHookResult = ReturnType<typeof useSearchNewsLazyQuery>;
export type SearchNewsQueryResult = ApolloReactCommon.QueryResult<SearchNewsQuery, SearchNewsQueryVariables>;
export const GetNewsShortContentDocument = gql`
    query GetNewsShortContent($payload: NewsInput) {
  news(payload: $payload) {
    data {
      _id
      title
      shortDescription
    }
  }
}
    `;
export type GetNewsShortContentComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetNewsShortContentQuery, GetNewsShortContentQueryVariables>, 'query'>;

    export const GetNewsShortContentComponent = (props: GetNewsShortContentComponentProps) => (
      <ApolloReactComponents.Query<GetNewsShortContentQuery, GetNewsShortContentQueryVariables> query={GetNewsShortContentDocument} {...props} />
    );
    
export type GetNewsShortContentProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetNewsShortContentQuery, GetNewsShortContentQueryVariables> & TChildProps;
export function withGetNewsShortContent<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetNewsShortContentQuery,
  GetNewsShortContentQueryVariables,
  GetNewsShortContentProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetNewsShortContentQuery, GetNewsShortContentQueryVariables, GetNewsShortContentProps<TChildProps>>(GetNewsShortContentDocument, {
      alias: 'getNewsShortContent',
      ...operationOptions
    });
};

/**
 * __useGetNewsShortContentQuery__
 *
 * To run a query within a React component, call `useGetNewsShortContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsShortContentQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsShortContentQuery({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useGetNewsShortContentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNewsShortContentQuery, GetNewsShortContentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNewsShortContentQuery, GetNewsShortContentQueryVariables>(GetNewsShortContentDocument, baseOptions);
      }
export function useGetNewsShortContentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNewsShortContentQuery, GetNewsShortContentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNewsShortContentQuery, GetNewsShortContentQueryVariables>(GetNewsShortContentDocument, baseOptions);
        }
export type GetNewsShortContentQueryHookResult = ReturnType<typeof useGetNewsShortContentQuery>;
export type GetNewsShortContentLazyQueryHookResult = ReturnType<typeof useGetNewsShortContentLazyQuery>;
export type GetNewsShortContentQueryResult = ApolloReactCommon.QueryResult<GetNewsShortContentQuery, GetNewsShortContentQueryVariables>;
export const GetNewsFullContentDocument = gql`
    query GetNewsFullContent($payload: NewsInput) {
  news(payload: $payload) {
    data {
      _id
      title
      description
      created_at
    }
  }
}
    `;
export type GetNewsFullContentComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetNewsFullContentQuery, GetNewsFullContentQueryVariables>, 'query'>;

    export const GetNewsFullContentComponent = (props: GetNewsFullContentComponentProps) => (
      <ApolloReactComponents.Query<GetNewsFullContentQuery, GetNewsFullContentQueryVariables> query={GetNewsFullContentDocument} {...props} />
    );
    
export type GetNewsFullContentProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetNewsFullContentQuery, GetNewsFullContentQueryVariables> & TChildProps;
export function withGetNewsFullContent<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetNewsFullContentQuery,
  GetNewsFullContentQueryVariables,
  GetNewsFullContentProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetNewsFullContentQuery, GetNewsFullContentQueryVariables, GetNewsFullContentProps<TChildProps>>(GetNewsFullContentDocument, {
      alias: 'getNewsFullContent',
      ...operationOptions
    });
};

/**
 * __useGetNewsFullContentQuery__
 *
 * To run a query within a React component, call `useGetNewsFullContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsFullContentQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsFullContentQuery({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useGetNewsFullContentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNewsFullContentQuery, GetNewsFullContentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNewsFullContentQuery, GetNewsFullContentQueryVariables>(GetNewsFullContentDocument, baseOptions);
      }
export function useGetNewsFullContentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNewsFullContentQuery, GetNewsFullContentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNewsFullContentQuery, GetNewsFullContentQueryVariables>(GetNewsFullContentDocument, baseOptions);
        }
export type GetNewsFullContentQueryHookResult = ReturnType<typeof useGetNewsFullContentQuery>;
export type GetNewsFullContentLazyQueryHookResult = ReturnType<typeof useGetNewsFullContentLazyQuery>;
export type GetNewsFullContentQueryResult = ApolloReactCommon.QueryResult<GetNewsFullContentQuery, GetNewsFullContentQueryVariables>;
export const GetNewsEditDocument = gql`
    query GetNewsEdit($payload: NewsInput) {
  news(payload: $payload) {
    data {
      _id
      title
      shortDescription
      description
      year
      month
    }
  }
}
    `;
export type GetNewsEditComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetNewsEditQuery, GetNewsEditQueryVariables>, 'query'>;

    export const GetNewsEditComponent = (props: GetNewsEditComponentProps) => (
      <ApolloReactComponents.Query<GetNewsEditQuery, GetNewsEditQueryVariables> query={GetNewsEditDocument} {...props} />
    );
    
export type GetNewsEditProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetNewsEditQuery, GetNewsEditQueryVariables> & TChildProps;
export function withGetNewsEdit<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetNewsEditQuery,
  GetNewsEditQueryVariables,
  GetNewsEditProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetNewsEditQuery, GetNewsEditQueryVariables, GetNewsEditProps<TChildProps>>(GetNewsEditDocument, {
      alias: 'getNewsEdit',
      ...operationOptions
    });
};

/**
 * __useGetNewsEditQuery__
 *
 * To run a query within a React component, call `useGetNewsEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsEditQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsEditQuery({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useGetNewsEditQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNewsEditQuery, GetNewsEditQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNewsEditQuery, GetNewsEditQueryVariables>(GetNewsEditDocument, baseOptions);
      }
export function useGetNewsEditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNewsEditQuery, GetNewsEditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNewsEditQuery, GetNewsEditQueryVariables>(GetNewsEditDocument, baseOptions);
        }
export type GetNewsEditQueryHookResult = ReturnType<typeof useGetNewsEditQuery>;
export type GetNewsEditLazyQueryHookResult = ReturnType<typeof useGetNewsEditLazyQuery>;
export type GetNewsEditQueryResult = ApolloReactCommon.QueryResult<GetNewsEditQuery, GetNewsEditQueryVariables>;
export const CreateRatingDocument = gql`
    mutation CreateRating($region: String, $month: Int, $year: Int, $rating: Float) {
  createRating(region: $region, month: $month, year: $year, rating: $rating) {
    _id
    region
    month
    year
    rating
    created_at
    updated_at
  }
}
    `;
export type CreateRatingMutationFn = ApolloReactCommon.MutationFunction<CreateRatingMutation, CreateRatingMutationVariables>;
export type CreateRatingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateRatingMutation, CreateRatingMutationVariables>, 'mutation'>;

    export const CreateRatingComponent = (props: CreateRatingComponentProps) => (
      <ApolloReactComponents.Mutation<CreateRatingMutation, CreateRatingMutationVariables> mutation={CreateRatingDocument} {...props} />
    );
    
export type CreateRatingProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateRatingMutation, CreateRatingMutationVariables> & TChildProps;
export function withCreateRating<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateRatingMutation,
  CreateRatingMutationVariables,
  CreateRatingProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateRatingMutation, CreateRatingMutationVariables, CreateRatingProps<TChildProps>>(CreateRatingDocument, {
      alias: 'createRating',
      ...operationOptions
    });
};

/**
 * __useCreateRatingMutation__
 *
 * To run a mutation, you first call `useCreateRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRatingMutation, { data, loading, error }] = useCreateRatingMutation({
 *   variables: {
 *      region: // value for 'region'
 *      month: // value for 'month'
 *      year: // value for 'year'
 *      rating: // value for 'rating'
 *   },
 * });
 */
export function useCreateRatingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRatingMutation, CreateRatingMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRatingMutation, CreateRatingMutationVariables>(CreateRatingDocument, baseOptions);
      }
export type CreateRatingMutationHookResult = ReturnType<typeof useCreateRatingMutation>;
export type CreateRatingMutationResult = ApolloReactCommon.MutationResult<CreateRatingMutation>;
export type CreateRatingMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRatingMutation, CreateRatingMutationVariables>;
export const CreateGlobalRatingDocument = gql`
    mutation CreateGlobalRating($month: Int, $year: Int, $rating: Float) {
  createGlobalRating(month: $month, year: $year, rating: $rating) {
    _id
    month
    year
    rating
    created_at
    updated_at
  }
}
    `;
export type CreateGlobalRatingMutationFn = ApolloReactCommon.MutationFunction<CreateGlobalRatingMutation, CreateGlobalRatingMutationVariables>;
export type CreateGlobalRatingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateGlobalRatingMutation, CreateGlobalRatingMutationVariables>, 'mutation'>;

    export const CreateGlobalRatingComponent = (props: CreateGlobalRatingComponentProps) => (
      <ApolloReactComponents.Mutation<CreateGlobalRatingMutation, CreateGlobalRatingMutationVariables> mutation={CreateGlobalRatingDocument} {...props} />
    );
    
export type CreateGlobalRatingProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateGlobalRatingMutation, CreateGlobalRatingMutationVariables> & TChildProps;
export function withCreateGlobalRating<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateGlobalRatingMutation,
  CreateGlobalRatingMutationVariables,
  CreateGlobalRatingProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateGlobalRatingMutation, CreateGlobalRatingMutationVariables, CreateGlobalRatingProps<TChildProps>>(CreateGlobalRatingDocument, {
      alias: 'createGlobalRating',
      ...operationOptions
    });
};

/**
 * __useCreateGlobalRatingMutation__
 *
 * To run a mutation, you first call `useCreateGlobalRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGlobalRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGlobalRatingMutation, { data, loading, error }] = useCreateGlobalRatingMutation({
 *   variables: {
 *      month: // value for 'month'
 *      year: // value for 'year'
 *      rating: // value for 'rating'
 *   },
 * });
 */
export function useCreateGlobalRatingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGlobalRatingMutation, CreateGlobalRatingMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGlobalRatingMutation, CreateGlobalRatingMutationVariables>(CreateGlobalRatingDocument, baseOptions);
      }
export type CreateGlobalRatingMutationHookResult = ReturnType<typeof useCreateGlobalRatingMutation>;
export type CreateGlobalRatingMutationResult = ApolloReactCommon.MutationResult<CreateGlobalRatingMutation>;
export type CreateGlobalRatingMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGlobalRatingMutation, CreateGlobalRatingMutationVariables>;
export const EditRatingDocument = gql`
    mutation EditRating($id: String, $rating: Float, $year: Int, $month: Int, $region: String) {
  editRating(id: $id, rating: $rating, month: $month, year: $year, region: $region) {
    _id
    region
    month
    year
    rating
    created_at
    updated_at
  }
}
    `;
export type EditRatingMutationFn = ApolloReactCommon.MutationFunction<EditRatingMutation, EditRatingMutationVariables>;
export type EditRatingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditRatingMutation, EditRatingMutationVariables>, 'mutation'>;

    export const EditRatingComponent = (props: EditRatingComponentProps) => (
      <ApolloReactComponents.Mutation<EditRatingMutation, EditRatingMutationVariables> mutation={EditRatingDocument} {...props} />
    );
    
export type EditRatingProps<TChildProps = {}> = ApolloReactHoc.MutateProps<EditRatingMutation, EditRatingMutationVariables> & TChildProps;
export function withEditRating<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EditRatingMutation,
  EditRatingMutationVariables,
  EditRatingProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, EditRatingMutation, EditRatingMutationVariables, EditRatingProps<TChildProps>>(EditRatingDocument, {
      alias: 'editRating',
      ...operationOptions
    });
};

/**
 * __useEditRatingMutation__
 *
 * To run a mutation, you first call `useEditRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRatingMutation, { data, loading, error }] = useEditRatingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rating: // value for 'rating'
 *      year: // value for 'year'
 *      month: // value for 'month'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useEditRatingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditRatingMutation, EditRatingMutationVariables>) {
        return ApolloReactHooks.useMutation<EditRatingMutation, EditRatingMutationVariables>(EditRatingDocument, baseOptions);
      }
export type EditRatingMutationHookResult = ReturnType<typeof useEditRatingMutation>;
export type EditRatingMutationResult = ApolloReactCommon.MutationResult<EditRatingMutation>;
export type EditRatingMutationOptions = ApolloReactCommon.BaseMutationOptions<EditRatingMutation, EditRatingMutationVariables>;
export const EditGlobalRatingDocument = gql`
    mutation EditGlobalRating($id: String, $rating: Float, $year: Int, $month: Int) {
  editGlobalRating(id: $id, rating: $rating, year: $year, month: $month) {
    _id
    month
    year
    rating
    created_at
    updated_at
  }
}
    `;
export type EditGlobalRatingMutationFn = ApolloReactCommon.MutationFunction<EditGlobalRatingMutation, EditGlobalRatingMutationVariables>;
export type EditGlobalRatingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditGlobalRatingMutation, EditGlobalRatingMutationVariables>, 'mutation'>;

    export const EditGlobalRatingComponent = (props: EditGlobalRatingComponentProps) => (
      <ApolloReactComponents.Mutation<EditGlobalRatingMutation, EditGlobalRatingMutationVariables> mutation={EditGlobalRatingDocument} {...props} />
    );
    
export type EditGlobalRatingProps<TChildProps = {}> = ApolloReactHoc.MutateProps<EditGlobalRatingMutation, EditGlobalRatingMutationVariables> & TChildProps;
export function withEditGlobalRating<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EditGlobalRatingMutation,
  EditGlobalRatingMutationVariables,
  EditGlobalRatingProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, EditGlobalRatingMutation, EditGlobalRatingMutationVariables, EditGlobalRatingProps<TChildProps>>(EditGlobalRatingDocument, {
      alias: 'editGlobalRating',
      ...operationOptions
    });
};

/**
 * __useEditGlobalRatingMutation__
 *
 * To run a mutation, you first call `useEditGlobalRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditGlobalRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editGlobalRatingMutation, { data, loading, error }] = useEditGlobalRatingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rating: // value for 'rating'
 *      year: // value for 'year'
 *      month: // value for 'month'
 *   },
 * });
 */
export function useEditGlobalRatingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditGlobalRatingMutation, EditGlobalRatingMutationVariables>) {
        return ApolloReactHooks.useMutation<EditGlobalRatingMutation, EditGlobalRatingMutationVariables>(EditGlobalRatingDocument, baseOptions);
      }
export type EditGlobalRatingMutationHookResult = ReturnType<typeof useEditGlobalRatingMutation>;
export type EditGlobalRatingMutationResult = ApolloReactCommon.MutationResult<EditGlobalRatingMutation>;
export type EditGlobalRatingMutationOptions = ApolloReactCommon.BaseMutationOptions<EditGlobalRatingMutation, EditGlobalRatingMutationVariables>;
export const RemoveRatingDocument = gql`
    mutation RemoveRating($id: String) {
  removeRating(id: $id)
}
    `;
export type RemoveRatingMutationFn = ApolloReactCommon.MutationFunction<RemoveRatingMutation, RemoveRatingMutationVariables>;
export type RemoveRatingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveRatingMutation, RemoveRatingMutationVariables>, 'mutation'>;

    export const RemoveRatingComponent = (props: RemoveRatingComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveRatingMutation, RemoveRatingMutationVariables> mutation={RemoveRatingDocument} {...props} />
    );
    
export type RemoveRatingProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RemoveRatingMutation, RemoveRatingMutationVariables> & TChildProps;
export function withRemoveRating<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveRatingMutation,
  RemoveRatingMutationVariables,
  RemoveRatingProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveRatingMutation, RemoveRatingMutationVariables, RemoveRatingProps<TChildProps>>(RemoveRatingDocument, {
      alias: 'removeRating',
      ...operationOptions
    });
};

/**
 * __useRemoveRatingMutation__
 *
 * To run a mutation, you first call `useRemoveRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRatingMutation, { data, loading, error }] = useRemoveRatingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveRatingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveRatingMutation, RemoveRatingMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveRatingMutation, RemoveRatingMutationVariables>(RemoveRatingDocument, baseOptions);
      }
export type RemoveRatingMutationHookResult = ReturnType<typeof useRemoveRatingMutation>;
export type RemoveRatingMutationResult = ApolloReactCommon.MutationResult<RemoveRatingMutation>;
export type RemoveRatingMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveRatingMutation, RemoveRatingMutationVariables>;
export const RemoveGlobalRatingDocument = gql`
    mutation RemoveGlobalRating($id: String) {
  removeGlobalRating(id: $id)
}
    `;
export type RemoveGlobalRatingMutationFn = ApolloReactCommon.MutationFunction<RemoveGlobalRatingMutation, RemoveGlobalRatingMutationVariables>;
export type RemoveGlobalRatingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveGlobalRatingMutation, RemoveGlobalRatingMutationVariables>, 'mutation'>;

    export const RemoveGlobalRatingComponent = (props: RemoveGlobalRatingComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveGlobalRatingMutation, RemoveGlobalRatingMutationVariables> mutation={RemoveGlobalRatingDocument} {...props} />
    );
    
export type RemoveGlobalRatingProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RemoveGlobalRatingMutation, RemoveGlobalRatingMutationVariables> & TChildProps;
export function withRemoveGlobalRating<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveGlobalRatingMutation,
  RemoveGlobalRatingMutationVariables,
  RemoveGlobalRatingProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveGlobalRatingMutation, RemoveGlobalRatingMutationVariables, RemoveGlobalRatingProps<TChildProps>>(RemoveGlobalRatingDocument, {
      alias: 'removeGlobalRating',
      ...operationOptions
    });
};

/**
 * __useRemoveGlobalRatingMutation__
 *
 * To run a mutation, you first call `useRemoveGlobalRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGlobalRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGlobalRatingMutation, { data, loading, error }] = useRemoveGlobalRatingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveGlobalRatingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveGlobalRatingMutation, RemoveGlobalRatingMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveGlobalRatingMutation, RemoveGlobalRatingMutationVariables>(RemoveGlobalRatingDocument, baseOptions);
      }
export type RemoveGlobalRatingMutationHookResult = ReturnType<typeof useRemoveGlobalRatingMutation>;
export type RemoveGlobalRatingMutationResult = ApolloReactCommon.MutationResult<RemoveGlobalRatingMutation>;
export type RemoveGlobalRatingMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveGlobalRatingMutation, RemoveGlobalRatingMutationVariables>;
export const CreateArticleDocument = gql`
    mutation CreateArticle($title: String, $description: String, $type: String, $region: String, $month: Int, $year: Int) {
  createArticle(title: $title, description: $description, type: $type, region: $region, month: $month, year: $year) {
    _id
    title
    type
    region
    month
    year
    created_at
    updated_at
  }
}
    `;
export type CreateArticleMutationFn = ApolloReactCommon.MutationFunction<CreateArticleMutation, CreateArticleMutationVariables>;
export type CreateArticleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateArticleMutation, CreateArticleMutationVariables>, 'mutation'>;

    export const CreateArticleComponent = (props: CreateArticleComponentProps) => (
      <ApolloReactComponents.Mutation<CreateArticleMutation, CreateArticleMutationVariables> mutation={CreateArticleDocument} {...props} />
    );
    
export type CreateArticleProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateArticleMutation, CreateArticleMutationVariables> & TChildProps;
export function withCreateArticle<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateArticleMutation,
  CreateArticleMutationVariables,
  CreateArticleProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateArticleMutation, CreateArticleMutationVariables, CreateArticleProps<TChildProps>>(CreateArticleDocument, {
      alias: 'createArticle',
      ...operationOptions
    });
};

/**
 * __useCreateArticleMutation__
 *
 * To run a mutation, you first call `useCreateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleMutation, { data, loading, error }] = useCreateArticleMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      type: // value for 'type'
 *      region: // value for 'region'
 *      month: // value for 'month'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useCreateArticleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArticleMutation, CreateArticleMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateArticleMutation, CreateArticleMutationVariables>(CreateArticleDocument, baseOptions);
      }
export type CreateArticleMutationHookResult = ReturnType<typeof useCreateArticleMutation>;
export type CreateArticleMutationResult = ApolloReactCommon.MutationResult<CreateArticleMutation>;
export type CreateArticleMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateArticleMutation, CreateArticleMutationVariables>;
export const EditArticleDocument = gql`
    mutation EditArticle($id: String, $title: String, $description: String, $region: String, $type: String, $month: Int, $year: Int) {
  editArticle(id: $id, title: $title, description: $description, region: $region, type: $type, month: $month, year: $year) {
    _id
    title
    type
    region
    month
    year
    created_at
    updated_at
  }
}
    `;
export type EditArticleMutationFn = ApolloReactCommon.MutationFunction<EditArticleMutation, EditArticleMutationVariables>;
export type EditArticleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditArticleMutation, EditArticleMutationVariables>, 'mutation'>;

    export const EditArticleComponent = (props: EditArticleComponentProps) => (
      <ApolloReactComponents.Mutation<EditArticleMutation, EditArticleMutationVariables> mutation={EditArticleDocument} {...props} />
    );
    
export type EditArticleProps<TChildProps = {}> = ApolloReactHoc.MutateProps<EditArticleMutation, EditArticleMutationVariables> & TChildProps;
export function withEditArticle<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EditArticleMutation,
  EditArticleMutationVariables,
  EditArticleProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, EditArticleMutation, EditArticleMutationVariables, EditArticleProps<TChildProps>>(EditArticleDocument, {
      alias: 'editArticle',
      ...operationOptions
    });
};

/**
 * __useEditArticleMutation__
 *
 * To run a mutation, you first call `useEditArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editArticleMutation, { data, loading, error }] = useEditArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      region: // value for 'region'
 *      type: // value for 'type'
 *      month: // value for 'month'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useEditArticleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditArticleMutation, EditArticleMutationVariables>) {
        return ApolloReactHooks.useMutation<EditArticleMutation, EditArticleMutationVariables>(EditArticleDocument, baseOptions);
      }
export type EditArticleMutationHookResult = ReturnType<typeof useEditArticleMutation>;
export type EditArticleMutationResult = ApolloReactCommon.MutationResult<EditArticleMutation>;
export type EditArticleMutationOptions = ApolloReactCommon.BaseMutationOptions<EditArticleMutation, EditArticleMutationVariables>;
export const RemoveArticleDocument = gql`
    mutation RemoveArticle($id: String) {
  removeArticle(id: $id)
}
    `;
export type RemoveArticleMutationFn = ApolloReactCommon.MutationFunction<RemoveArticleMutation, RemoveArticleMutationVariables>;
export type RemoveArticleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveArticleMutation, RemoveArticleMutationVariables>, 'mutation'>;

    export const RemoveArticleComponent = (props: RemoveArticleComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveArticleMutation, RemoveArticleMutationVariables> mutation={RemoveArticleDocument} {...props} />
    );
    
export type RemoveArticleProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RemoveArticleMutation, RemoveArticleMutationVariables> & TChildProps;
export function withRemoveArticle<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveArticleMutation,
  RemoveArticleMutationVariables,
  RemoveArticleProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveArticleMutation, RemoveArticleMutationVariables, RemoveArticleProps<TChildProps>>(RemoveArticleDocument, {
      alias: 'removeArticle',
      ...operationOptions
    });
};

/**
 * __useRemoveArticleMutation__
 *
 * To run a mutation, you first call `useRemoveArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeArticleMutation, { data, loading, error }] = useRemoveArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveArticleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveArticleMutation, RemoveArticleMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveArticleMutation, RemoveArticleMutationVariables>(RemoveArticleDocument, baseOptions);
      }
export type RemoveArticleMutationHookResult = ReturnType<typeof useRemoveArticleMutation>;
export type RemoveArticleMutationResult = ApolloReactCommon.MutationResult<RemoveArticleMutation>;
export type RemoveArticleMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveArticleMutation, RemoveArticleMutationVariables>;
export const CreateNewsDocument = gql`
    mutation CreateNews($title: String, $description: String, $shortDescription: String, $month: Int, $year: Int) {
  createNews(title: $title, description: $description, shortDescription: $shortDescription, month: $month, year: $year) {
    _id
    title
    month
    year
    created_at
    updated_at
  }
}
    `;
export type CreateNewsMutationFn = ApolloReactCommon.MutationFunction<CreateNewsMutation, CreateNewsMutationVariables>;
export type CreateNewsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateNewsMutation, CreateNewsMutationVariables>, 'mutation'>;

    export const CreateNewsComponent = (props: CreateNewsComponentProps) => (
      <ApolloReactComponents.Mutation<CreateNewsMutation, CreateNewsMutationVariables> mutation={CreateNewsDocument} {...props} />
    );
    
export type CreateNewsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateNewsMutation, CreateNewsMutationVariables> & TChildProps;
export function withCreateNews<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateNewsMutation,
  CreateNewsMutationVariables,
  CreateNewsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateNewsMutation, CreateNewsMutationVariables, CreateNewsProps<TChildProps>>(CreateNewsDocument, {
      alias: 'createNews',
      ...operationOptions
    });
};

/**
 * __useCreateNewsMutation__
 *
 * To run a mutation, you first call `useCreateNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewsMutation, { data, loading, error }] = useCreateNewsMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      shortDescription: // value for 'shortDescription'
 *      month: // value for 'month'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useCreateNewsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateNewsMutation, CreateNewsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateNewsMutation, CreateNewsMutationVariables>(CreateNewsDocument, baseOptions);
      }
export type CreateNewsMutationHookResult = ReturnType<typeof useCreateNewsMutation>;
export type CreateNewsMutationResult = ApolloReactCommon.MutationResult<CreateNewsMutation>;
export type CreateNewsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateNewsMutation, CreateNewsMutationVariables>;
export const EditNewsDocument = gql`
    mutation EditNews($id: String, $title: String, $shortDescription: String, $description: String, $month: Int, $year: Int) {
  editNews(id: $id, title: $title, description: $description, shortDescription: $shortDescription, month: $month, year: $year) {
    _id
    title
    month
    year
    created_at
    updated_at
  }
}
    `;
export type EditNewsMutationFn = ApolloReactCommon.MutationFunction<EditNewsMutation, EditNewsMutationVariables>;
export type EditNewsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditNewsMutation, EditNewsMutationVariables>, 'mutation'>;

    export const EditNewsComponent = (props: EditNewsComponentProps) => (
      <ApolloReactComponents.Mutation<EditNewsMutation, EditNewsMutationVariables> mutation={EditNewsDocument} {...props} />
    );
    
export type EditNewsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<EditNewsMutation, EditNewsMutationVariables> & TChildProps;
export function withEditNews<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EditNewsMutation,
  EditNewsMutationVariables,
  EditNewsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, EditNewsMutation, EditNewsMutationVariables, EditNewsProps<TChildProps>>(EditNewsDocument, {
      alias: 'editNews',
      ...operationOptions
    });
};

/**
 * __useEditNewsMutation__
 *
 * To run a mutation, you first call `useEditNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editNewsMutation, { data, loading, error }] = useEditNewsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      shortDescription: // value for 'shortDescription'
 *      description: // value for 'description'
 *      month: // value for 'month'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useEditNewsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditNewsMutation, EditNewsMutationVariables>) {
        return ApolloReactHooks.useMutation<EditNewsMutation, EditNewsMutationVariables>(EditNewsDocument, baseOptions);
      }
export type EditNewsMutationHookResult = ReturnType<typeof useEditNewsMutation>;
export type EditNewsMutationResult = ApolloReactCommon.MutationResult<EditNewsMutation>;
export type EditNewsMutationOptions = ApolloReactCommon.BaseMutationOptions<EditNewsMutation, EditNewsMutationVariables>;
export const RemoveNewsDocument = gql`
    mutation RemoveNews($id: String) {
  removeNews(id: $id)
}
    `;
export type RemoveNewsMutationFn = ApolloReactCommon.MutationFunction<RemoveNewsMutation, RemoveNewsMutationVariables>;
export type RemoveNewsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveNewsMutation, RemoveNewsMutationVariables>, 'mutation'>;

    export const RemoveNewsComponent = (props: RemoveNewsComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveNewsMutation, RemoveNewsMutationVariables> mutation={RemoveNewsDocument} {...props} />
    );
    
export type RemoveNewsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RemoveNewsMutation, RemoveNewsMutationVariables> & TChildProps;
export function withRemoveNews<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveNewsMutation,
  RemoveNewsMutationVariables,
  RemoveNewsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveNewsMutation, RemoveNewsMutationVariables, RemoveNewsProps<TChildProps>>(RemoveNewsDocument, {
      alias: 'removeNews',
      ...operationOptions
    });
};

/**
 * __useRemoveNewsMutation__
 *
 * To run a mutation, you first call `useRemoveNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeNewsMutation, { data, loading, error }] = useRemoveNewsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveNewsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveNewsMutation, RemoveNewsMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveNewsMutation, RemoveNewsMutationVariables>(RemoveNewsDocument, baseOptions);
      }
export type RemoveNewsMutationHookResult = ReturnType<typeof useRemoveNewsMutation>;
export type RemoveNewsMutationResult = ApolloReactCommon.MutationResult<RemoveNewsMutation>;
export type RemoveNewsMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveNewsMutation, RemoveNewsMutationVariables>;
export const GetFooterDocument = gql`
    query GetFooter {
  footer {
    _id
    content
  }
}
    `;
export type GetFooterComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetFooterQuery, GetFooterQueryVariables>, 'query'>;

    export const GetFooterComponent = (props: GetFooterComponentProps) => (
      <ApolloReactComponents.Query<GetFooterQuery, GetFooterQueryVariables> query={GetFooterDocument} {...props} />
    );
    
export type GetFooterProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetFooterQuery, GetFooterQueryVariables> & TChildProps;
export function withGetFooter<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetFooterQuery,
  GetFooterQueryVariables,
  GetFooterProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetFooterQuery, GetFooterQueryVariables, GetFooterProps<TChildProps>>(GetFooterDocument, {
      alias: 'getFooter',
      ...operationOptions
    });
};

/**
 * __useGetFooterQuery__
 *
 * To run a query within a React component, call `useGetFooterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFooterQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFooterQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFooterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFooterQuery, GetFooterQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFooterQuery, GetFooterQueryVariables>(GetFooterDocument, baseOptions);
      }
export function useGetFooterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFooterQuery, GetFooterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFooterQuery, GetFooterQueryVariables>(GetFooterDocument, baseOptions);
        }
export type GetFooterQueryHookResult = ReturnType<typeof useGetFooterQuery>;
export type GetFooterLazyQueryHookResult = ReturnType<typeof useGetFooterLazyQuery>;
export type GetFooterQueryResult = ApolloReactCommon.QueryResult<GetFooterQuery, GetFooterQueryVariables>;
export const EditFooterDocument = gql`
    mutation EditFooter($id: String, $content: String) {
  editFooter(id: $id, content: $content) {
    _id
    content
  }
}
    `;
export type EditFooterMutationFn = ApolloReactCommon.MutationFunction<EditFooterMutation, EditFooterMutationVariables>;
export type EditFooterComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditFooterMutation, EditFooterMutationVariables>, 'mutation'>;

    export const EditFooterComponent = (props: EditFooterComponentProps) => (
      <ApolloReactComponents.Mutation<EditFooterMutation, EditFooterMutationVariables> mutation={EditFooterDocument} {...props} />
    );
    
export type EditFooterProps<TChildProps = {}> = ApolloReactHoc.MutateProps<EditFooterMutation, EditFooterMutationVariables> & TChildProps;
export function withEditFooter<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EditFooterMutation,
  EditFooterMutationVariables,
  EditFooterProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, EditFooterMutation, EditFooterMutationVariables, EditFooterProps<TChildProps>>(EditFooterDocument, {
      alias: 'editFooter',
      ...operationOptions
    });
};

/**
 * __useEditFooterMutation__
 *
 * To run a mutation, you first call `useEditFooterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFooterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFooterMutation, { data, loading, error }] = useEditFooterMutation({
 *   variables: {
 *      id: // value for 'id'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useEditFooterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditFooterMutation, EditFooterMutationVariables>) {
        return ApolloReactHooks.useMutation<EditFooterMutation, EditFooterMutationVariables>(EditFooterDocument, baseOptions);
      }
export type EditFooterMutationHookResult = ReturnType<typeof useEditFooterMutation>;
export type EditFooterMutationResult = ApolloReactCommon.MutationResult<EditFooterMutation>;
export type EditFooterMutationOptions = ApolloReactCommon.BaseMutationOptions<EditFooterMutation, EditFooterMutationVariables>;
export const GetUsersDocument = gql`
    query GetUsers {
  users {
    name
    email
  }
}
    `;
export type GetUsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUsersQuery, GetUsersQueryVariables>, 'query'>;

    export const GetUsersComponent = (props: GetUsersComponentProps) => (
      <ApolloReactComponents.Query<GetUsersQuery, GetUsersQueryVariables> query={GetUsersDocument} {...props} />
    );
    
export type GetUsersProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetUsersQuery, GetUsersQueryVariables> & TChildProps;
export function withGetUsers<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUsersQuery,
  GetUsersQueryVariables,
  GetUsersProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetUsersQuery, GetUsersQueryVariables, GetUsersProps<TChildProps>>(GetUsersDocument, {
      alias: 'getUsers',
      ...operationOptions
    });
};

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
      }
export function useGetUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = ApolloReactCommon.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const LoginDocument = gql`
    mutation Login($email: String, $password: String) {
  login(payload: {email: $email, password: $password}) {
    email
    name
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;
export type LoginComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LoginMutation, LoginMutationVariables>, 'mutation'>;

    export const LoginComponent = (props: LoginComponentProps) => (
      <ApolloReactComponents.Mutation<LoginMutation, LoginMutationVariables> mutation={LoginDocument} {...props} />
    );
    
export type LoginProps<TChildProps = {}> = ApolloReactHoc.MutateProps<LoginMutation, LoginMutationVariables> & TChildProps;
export function withLogin<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LoginMutation,
  LoginMutationVariables,
  LoginProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, LoginMutation, LoginMutationVariables, LoginProps<TChildProps>>(LoginDocument, {
      alias: 'login',
      ...operationOptions
    });
};

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($name: String, $email: String, $password: String) {
  createUser(name: $name, email: $email, password: $password) {
    name
    email
  }
}
    `;
export type CreateUserMutationFn = ApolloReactCommon.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;
export type CreateUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateUserMutation, CreateUserMutationVariables>, 'mutation'>;

    export const CreateUserComponent = (props: CreateUserComponentProps) => (
      <ApolloReactComponents.Mutation<CreateUserMutation, CreateUserMutationVariables> mutation={CreateUserDocument} {...props} />
    );
    
export type CreateUserProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateUserMutation, CreateUserMutationVariables> & TChildProps;
export function withCreateUser<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateUserMutation,
  CreateUserMutationVariables,
  CreateUserProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateUserMutation, CreateUserMutationVariables, CreateUserProps<TChildProps>>(CreateUserDocument, {
      alias: 'createUser',
      ...operationOptions
    });
};

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = ApolloReactCommon.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const RemoveUserDocument = gql`
    mutation RemoveUser($email: String) {
  removeUser(email: $email)
}
    `;
export type RemoveUserMutationFn = ApolloReactCommon.MutationFunction<RemoveUserMutation, RemoveUserMutationVariables>;
export type RemoveUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveUserMutation, RemoveUserMutationVariables>, 'mutation'>;

    export const RemoveUserComponent = (props: RemoveUserComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveUserMutation, RemoveUserMutationVariables> mutation={RemoveUserDocument} {...props} />
    );
    
export type RemoveUserProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RemoveUserMutation, RemoveUserMutationVariables> & TChildProps;
export function withRemoveUser<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveUserMutation,
  RemoveUserMutationVariables,
  RemoveUserProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveUserMutation, RemoveUserMutationVariables, RemoveUserProps<TChildProps>>(RemoveUserDocument, {
      alias: 'removeUser',
      ...operationOptions
    });
};

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRemoveUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveUserMutation, RemoveUserMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(RemoveUserDocument, baseOptions);
      }
export type RemoveUserMutationHookResult = ReturnType<typeof useRemoveUserMutation>;
export type RemoveUserMutationResult = ApolloReactCommon.MutationResult<RemoveUserMutation>;
export type RemoveUserMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveUserMutation, RemoveUserMutationVariables>;
export const EditUserDocument = gql`
    mutation EditUser($payload: EditInput) {
  editUser(payload: $payload) {
    name
  }
}
    `;
export type EditUserMutationFn = ApolloReactCommon.MutationFunction<EditUserMutation, EditUserMutationVariables>;
export type EditUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditUserMutation, EditUserMutationVariables>, 'mutation'>;

    export const EditUserComponent = (props: EditUserComponentProps) => (
      <ApolloReactComponents.Mutation<EditUserMutation, EditUserMutationVariables> mutation={EditUserDocument} {...props} />
    );
    
export type EditUserProps<TChildProps = {}> = ApolloReactHoc.MutateProps<EditUserMutation, EditUserMutationVariables> & TChildProps;
export function withEditUser<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EditUserMutation,
  EditUserMutationVariables,
  EditUserProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, EditUserMutation, EditUserMutationVariables, EditUserProps<TChildProps>>(EditUserDocument, {
      alias: 'editUser',
      ...operationOptions
    });
};

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useEditUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditUserMutation, EditUserMutationVariables>) {
        return ApolloReactHooks.useMutation<EditUserMutation, EditUserMutationVariables>(EditUserDocument, baseOptions);
      }
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = ApolloReactCommon.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = ApolloReactCommon.BaseMutationOptions<EditUserMutation, EditUserMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;
export type LogoutComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LogoutMutation, LogoutMutationVariables>, 'mutation'>;

    export const LogoutComponent = (props: LogoutComponentProps) => (
      <ApolloReactComponents.Mutation<LogoutMutation, LogoutMutationVariables> mutation={LogoutDocument} {...props} />
    );
    
export type LogoutProps<TChildProps = {}> = ApolloReactHoc.MutateProps<LogoutMutation, LogoutMutationVariables> & TChildProps;
export function withLogout<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LogoutMutation,
  LogoutMutationVariables,
  LogoutProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, LogoutMutation, LogoutMutationVariables, LogoutProps<TChildProps>>(LogoutDocument, {
      alias: 'logout',
      ...operationOptions
    });
};

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;