/*
    1/10/2019
    4:17 PM
    Created by Sanzhar Danybayev
*/

import React, {useEffect, useState} from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {useGetArticlesShortLazyQuery} from 'generated/graphql'
import onError from "../../../misc/graphQLError";
import swal from "sweetalert";
import PaginationContainer from "./PaginationContainer";
import {DialogActions, DialogContent, makeStyles, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    dialog: {
        padding: '1em'
    }
}))

const MarkerContainer = ({selectedElement, payload, zoomBack}) => {

    const [articles, setArticles] = useState([])
    const [open, setOpen] = useState(false)

    const classes = useStyles()

    const [getArticles] = useGetArticlesShortLazyQuery({
        fetchPolicy: 'no-cache',
        onError: (e) => {
            onError(e.graphQLErrors, () => {
                swal('Ошибка', 'Не получилось получить данные!', 'error')
            })
        },
        onCompleted: (data) => {
            const {articles: payload} = data
            if (payload.data) {
                const articles = payload.data
                setArticles(articles)
            }
        }
    });

    const updatedArticles = () => {
        let filters = []
        if (payload.selectedMonth) {
            filters.push({
                fieldName: 'month',
                value: payload.selectedMonth + ''
            })
        }

        if (payload.selectedYear) {
            filters.push({
                fieldName: 'year',
                value: payload.selectedYear + ''
            })
        }

        if (payload.selectedTypes.length > 0) {
            filters.push({
                fieldName: 'type',
                value: payload.selectedTypes.join(',')
            })
        }


        if (selectedElement?.rating?.region) {
            filters.push({
                fieldName: 'region',
                value: selectedElement?.rating?.region + ','
            })
        }

        getArticles({
            variables: {
                payload: {
                    filters
                }
            }
        })
    }

    useEffect(() => updatedArticles(), [payload.selectedMonth, payload.selectedYear, payload.selectedTypes, payload.selectedRegions, selectedElement])
    useEffect(() => updatedArticles(), [])

    useEffect(() => {
        if (selectedElement?.timeStamp) {
            setOpen(true)
        }
    }, [selectedElement])

    return (
        <Dialog onClose={() => setOpen(false) || zoomBack()} aria-labelledby="simple-dialog-title"
                open={open}>

            <DialogTitle id="simple-dialog-title" style={{textAlign: 'center'}}>{selectedElement?.rating?.region} |
                Рейтинг {selectedElement?.rating?.rating ?? 0}</DialogTitle>
            <DialogContent>
                <PerfectScrollbar>
                    <div style={{maxHeight: '300px'}}>
                        {articles.length > 0 ? <PaginationContainer data={articles}/> : <Typography variant={'subtitle1'} align={'center'}>
                            Нарушений нет
                        </Typography>}
                    </div>
                </PerfectScrollbar>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false) || zoomBack()}>Закрыть</Button>
            </DialogActions>
        </Dialog>
    )

}


export default MarkerContainer
