import React from 'react';
import {
    FacebookIcon,
    FacebookShareButton,
    MailruIcon,
    MailruShareButton,
    OKIcon,
    OKShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    ViberIcon,
    ViberShareButton,
    VKShareButton, WhatsappIcon,
    WhatsappShareButton
} from "react-share";

import styled from 'styled-components'
import {Typography} from "@material-ui/core";

const Wrapper = styled.div`
    width: 250px;
    display: flex;
    flex-direction: row;
    justify-content: space-between
`

const Index = () => {
    return (
        <Wrapper>
            <FacebookShareButton url={window.location.href}><FacebookIcon size={32}
                                                                                                            round={true}/></FacebookShareButton>
            <MailruShareButton url={window.location.href}> <MailruIcon size={32} round={true}/> </MailruShareButton>
            <OKShareButton url={window.location.href}> <OKIcon size={32} round={true}/> </OKShareButton>
            <TelegramShareButton url={window.location.href}> <TelegramIcon size={32} round={true}/>
            </TelegramShareButton>
            <TwitterShareButton url={window.location.href}> <TwitterIcon size={32} round={true}/> </TwitterShareButton>
            <ViberShareButton url={window.location.href}> <ViberIcon size={32} round={true}/> </ViberShareButton>
            <VKShareButton url={window.location.href}> <VKShareButton size={32} round={true}/> </VKShareButton>
            <WhatsappShareButton url={window.location.href}> <WhatsappIcon size={32} round={true}/>
            </WhatsappShareButton>
        </Wrapper>
    );
};

export default Index;