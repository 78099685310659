import React, {Suspense} from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import {Redirect, Route, Switch} from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "components/Header/Header.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import dr from "routes/dashboard.jsx";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx";

import image from "assets/img/sidebar-2.jpg";
import RoutesAdapter from "../routes/adapters/RoutesAdapter";
import ThemeContext from "components/ThemePicker/context/themes";
import LinearProgress from "@material-ui/core/LinearProgress";
import SidebarPureComponent from "../components/reuse/Util/SidebarPureComponent";

import helpers from "../misc/helpers";
import NotFound from "../views/General/NotFound";

const {authFetch} = helpers

let dashboardRoutes = dr;

var ps;

class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: false,
            menuOpen: true,
            miniActive: false,
            isBoss: false,
            routes: [],
            theme: "",
            showCard: true
        };


        this.applyStyles()

        this.resizeFunction = this.resizeFunction.bind(this);
    }


    resetStyles = () => {
        this.setState({
            showCard: true,
            menuOpen: true,
            theme: ''
        });
    }
    applyStyles = (reset) => {
        if (reset) {
            this.resetStyles()
            return;
        }
        if (window.location.pathname.indexOf("/dynamicpage/") !== -1) {
            const pageID = window.location.pathname.split("/")[2];
            if (pageID && pageID.length === 24) {
                authFetch({
                    url: `${process.env.REACT_APP_HOST}/layout/theme?pageID=${pageID}`,
                    callback: res => {
                        if (res.pageStyle.length === 0) {
                            this.resetStyles()
                        } else {
                            const {showCard, showMenu, theme} = res.pageStyle[0];
                            if (theme) {
                                this.setState({
                                    showCard: showCard,
                                    menuOpen: showMenu,
                                    theme: theme
                                });
                            }
                        }
                    }
                })
            }
        }
    }

    componentWillMount() {
        this.setState({
            routes: dashboardRoutes
        });
    }


    /**
     * @description Lazy load dynamic routes
     *
     **/
    getDynamicRoutes = (routes) => {
        let s = RoutesAdapter.getDynamicRoutes(routes);
        this.setState({
            loadedRoutes: false
        });
        s.then(res => {
            this.setState({
                routes: res,
                loadedRoutes: true
            });
        });
    };

    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.refs.mainPanel, {
                suppressScrollX: true,
                suppressScrollY: false
            });
            document.body.style.overflow = "hidden";
        }

        window.addEventListener("resize", this.resizeFunction);

        // This stuff is created for the sake of dynamic pages
        let routes = Object.assign([], this.state.routes);
        this.getDynamicRoutes(routes);


    }

    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
        }
        window.removeEventListener("resize", this.resizeFunction);
    }

    componentDidUpdate(e) {
        if (e.history.location.pathname !== e.location.pathname) {
            if (e.history.location.pathname.indexOf('/dynamicpage/') === -1 || e.history.location.pathname.indexOf('/dynamicpage/all') !== -1) {
                this.applyStyles(true)
            } else {
                this.applyStyles()
            }

            this.refs.mainPanel.scrollTop = 0;
            if (this.state.mobileOpen) {
                this.setState({mobileOpen: false});
            }
        }
    }


    handleDrawerToggle = () => {
        this.setState({mobileOpen: !this.state.mobileOpen});
    };

    handleMenuToggle = () => {
        this.setState({menuOpen: !this.state.menuOpen});
    };

    getRoute() {
        return this.props.location.pathname !== "/maps/full-screen-maps";
    }

    sidebarMinimize() {
        this.setState({miniActive: !this.state.miniActive});
    }

    resizeFunction() {
        if (window.innerWidth >= 960) {
            this.setState({mobileOpen: false});
        }
    }

    toggleTheme = (themeName) => {


        this.setState({
            theme: themeName
        });
    };


    render() {
        const {classes, ...rest} = this.props;
        const mainPanel =
            classes.mainPanel +
            " " +
            cx({
                [classes.mainPanelSidebarMini]: this.state.miniActive,
                [classes.mainPanelWithPerfectScrollbar]:
                navigator.platform.indexOf("Win") > -1
            });

        return (
            <div className={classes.wrapper}>
                <ThemeContext.Provider value={{
                    theme: this.state.theme,
                    showCard: this.state.showCard,
                    toggleTheme: this.toggleTheme,
                    showMenu: this.state.menuOpen,
                    toggleCard: () => this.setState({showCard: !this.state.showCard}),
                    toggleMenu: () => this.handleMenuToggle()
                }}>
                    {this.state.menuOpen ? <Sidebar
                        routes={this.state.routes}
                        logoText={"Excelsior"}
                        logo={false}
                        loadedRoutes={this.state.loadedRoutes}
                        image={image}
                        handleDrawerToggle={this.handleDrawerToggle}
                        open={this.state.mobileOpen}
                        menuOpen={this.state.menuOpen}
                        color="blue"
                        bgColor="black"
                        miniActive={this.state.miniActive}
                        {...rest}
                    /> : null}

                    <div style={{width: this.state.menuOpen ? "" : "100%"}}
                         className={[mainPanel, this.state.theme].join(" ")}
                         id={"mainPanel"} ref="mainPanel">

                        <Header
                            sidebarMinimize={this.sidebarMinimize.bind(this)}
                            miniActive={this.state.miniActive}
                            routes={localStorage.getItem("category") ? this.state.routes.filter(route => route.protected !== true) : this.state.routes}
                            handleDrawerToggle={this.handleDrawerToggle}
                            {...rest}
                        />
                        {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
                        <SidebarPureComponent mini={this.state.miniActive} location={this.props.location}
                                              match={this.props.match}>
                            {this.getRoute() ? (
                                <div className={classes.content}>
                                    <div className={classes.container}>
                                        {
                                            <Suspense fallback={<LinearProgress/>}>
                                                <Switch>
                                                    {this.state.routes.map((prop, key) => {
                                                        if (prop.protected && localStorage.getItem("category")) {
                                                            return "";
                                                        } else {
                                                            if (prop.redirect)
                                                                return <Redirect from={prop.path} to={prop.pathTo}
                                                                                 key={key}/>;
                                                            if (prop.collapse) {
                                                                return prop.views.map((prop, key) => {
                                                                    return (
                                                                        <Route
                                                                            path={prop.pathTemplate ? prop.pathTemplate : prop.path}
                                                                            component={prop.component}
                                                                            key={key}/>
                                                                    );
                                                                });
                                                            } else {
                                                                return <Route
                                                                    path={prop.pathTemplate ? prop.pathTemplate : prop.path}
                                                                    exact={true}
                                                                    strict={false}
                                                                    component={prop.component} key={key}/>;
                                                            }

                                                        }
                                                    })}
                                                    <Route  component={NotFound} />
                                                </Switch>
                                            </Suspense>
                                        }</div>
                                </div>
                            ) : (
                                <div className={classes.map}>{<Switch>
                                    {this.state.routes.map((prop, key) => {
                                        if (prop.protected && localStorage.getItem("category")) {
                                            return "";
                                        } else {
                                            if (prop.redirect)
                                                return <Redirect from={prop.path} to={prop.pathTo} key={key}/>;
                                            if (prop.collapse) {
                                                return prop.views.map((prop, key) => {
                                                    return (
                                                        <Route path={prop.path} component={prop.component} key={key}/>
                                                    );
                                                });
                                            }
                                            return <Route path={prop.path} component={prop.component} key={key}/>;
                                        }
                                    })}

                                </Switch>}</div>
                            )}
                        </SidebarPureComponent>
                    </div>
                </ThemeContext.Provider>
            </div>
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(appStyle)(Dashboard);
