import React from 'react';
import {YMInitializer} from "react-yandex-metrika";

const isProd = () => process.env.REACT_APP_ENV === 'prod'


const WithYandexAnalytics = ({children}) => {
    return (
        <React.Fragment>
            {children}
            {isProd() && <YMInitializer accounts={[58221337]} options={{
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
                webvisor: true
            }} version="2"/>}
        </React.Fragment>
    );
};

export default WithYandexAnalytics;
