const pagesStyle = theme => ({
    '@-webkit-keyframes Gradient': {
        '0%': {backgroundPosition: '0% 50%'},
        '50%': {backgroundPosition: '100% 50%'},
        '100%': {backgroundPosition: '0% 50%'}
    },
    //
    '@-moz-keyframes Gradient': {
        '0%': {backgroundPosition: '0% 50%'},
        '50%': {backgroundPosition: '100% 50%'},
        '100%': {backgroundPosition: '0% 50%'}
    },

    '@keyframes Gradient': {
        '0%': {backgroundPosition: '0% 50%'},
        '50%': {backgroundPosition: '100% 50%'},
        '100%': {backgroundPosition: '0% 50%'}
    },
    wrapper: {
        'background': ' linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB)',
        'background-size': ' 400% 400%',
        '-webkit-animation': '  $Gradient 15s ease infinite',
        '-moz-animation': '  $Gradient 15s ease infinite',
        'animation': ' $Gradient 15s ease infinite',
        height: "auto",
        minHeight: "100vh",
        position: "relative",
        top: "0"
    },
    fullPage: {
        padding: "120px 0",
        position: "relative",
        minHeight: "100vh",
        display: "flex!important",
        margin: "0",
        border: "0",
        color: "#fff",
        alignItems: "center",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        height: "100%",
        [theme.breakpoints.down("sm")]: {
            minHeight: "fit-content!important"
        },

    }
});

export default pagesStyle;
