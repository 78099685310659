
const onError = (e, callback = () => {})  => {
    if(e && e.length > 0){
        const error = e.pop()
        if(error.message.statusCode === 403){
            localStorage.removeItem('email')
            localStorage.removeItem('name')
            localStorage.removeItem('role')
            window.location.replace('/pages/login-page')
        }
        callback()
    }
}

export default onError