// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withRouter} from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useSearchNewsLazyQuery} from "../../../generated/graphql";
import onError from "../../../misc/graphQLError";
import swal from "sweetalert";

function sleep(delay = 0) {
    return new Promise(resolve => {
        setTimeout(resolve, delay);
    });
}

function Search(props) {
    const [open, setOpen] = React.useState(false);
    const [debounce, setDebounce] = React.useState(null);
    const [value, setValue] = React.useState('')
    const [options, setOptions] = React.useState([]);
    const [loading, setLoading] = React.useState([]);


    const [search, {loading: dataLoading}] = useSearchNewsLazyQuery({
        fetchPolicy: 'network-only',
        onError: (e) => {
            onError(e.graphQLErrors, () => {
                console.log(e)
                swal('Ошибка', 'Не получилось добавить!', 'error')
            })
        },
        onCompleted: (data) => {
            if (data.searchNews) {
                setOptions(data.searchNews.map(el => {
                    return {
                        id: el._id,
                        name: el.title
                    }
                }))
                setLoading(false)
            }
        }
    });

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }
        //
        // search({
        //     variables: {
        //         text
        //     }
        // })


        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        let active = true;

        setLoading(true)
        if(debounce){
            clearTimeout(debounce)
            setDebounce(setTimeout(() => {
                search({
                    variables: {
                        text: value
                    }
                })
            }, 500))
        } else {
            setDebounce(setTimeout(() => {
                search({
                    variables: {
                        text: value
                    }
                })
            }, 500))
        }



        return () => {
            active = false;
        };
    }, [value])

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            id="search-news"
            style={{width: '100%'}}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            filterOptions={(options, state) => options}
            filterSelectedOptions={false}
            onChange={(e, value) => props.history.push(`/news/${value.id}`)}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={option => option.name}
            options={options}
            loading={loading}
            loadingText={'Поиск ...'}
            noOptionsText={'Ничего не найдено'}
            renderInput={params => (
                <React.Fragment>
                    <TextField
                        {...params}
                        label="Поиск новостей"
                        fullWidth
                        variant="outlined"
                        onChange={e => setValue(e.target.value)}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                </React.Fragment>
            )}
        />
    );
}

export default withRouter(Search)