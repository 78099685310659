import React from 'react';
import AppHeader from "../../components/AppHeader";
import {Container, Typography} from "@material-ui/core";
import Footer from "views/Footer/View";

const NotFound = () => {
    return (
        <React.Fragment>
            <AppHeader/>
            <Container>
                <Typography variant={'h1'} style={{height: '100vh', marginTop: '-64px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}} align={'center'}>404. Страница не найдена</Typography>
            </Container>
            <Footer/>
        </React.Fragment>
    );
};

export default NotFound;
