import React, { useState} from 'react';
import {withRouter} from 'react-router-dom'
import {Hidden} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import appStyle from "../../assets/jss/material-dashboard-pro-react/layouts/appStyle";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const Index = ({classes, history}) => {
    const [menuOpen, setMenuOpen] = useState(false)

    return (
        <div>
            <Hidden only={['md', 'lg', 'xl']}>
                <AppBar className={classes.appBar} position="fixed">
                    <Toolbar>
                        <div className={classes.appBar}
                             style={{display: 'flex', alignItems: 'center', flexDirection: 'row'}}>
                            <div
                                className={`hamburger hamburger--spin ${menuOpen ? 'is-active' : ''}`}
                                onClick={() => setMenuOpen(!menuOpen)}>
                                <div className="hamburger-box">
                                    <div className="hamburger-inner"/>
                                </div>
                            </div>
                            <Typography style={{flexGrow: 1, textAlign: 'center'}} variant={'subtitle2'}>Индекс
                                Тревожности</Typography>
                        </div>
                    </Toolbar>
                </AppBar>
            </Hidden>
            <Hidden only={['sm', 'xs']}>
                <div className={classes.root}>
                    <AppBar className={classes.appBar} position="fixed">
                        <Toolbar>
                            <Container>
                                <div className={classes.appBarCenter}>
                                    <Typography variant={'subtitle2'}>Индекс Тревожности</Typography>
                                    <div className={classes.weatherContainer}>
                                        <Button color={'default'} onClick={() => history.push('/')} style={{
                                            color: 'white'
                                        }}>Карта</Button>
                                        <Button color={'default'} onClick={() => history.push('/news')} style={{
                                            color: 'white'
                                        }}>Новости</Button>
                                    </div>
                                </div>
                            </Container>
                        </Toolbar>
                    </AppBar>
                </div>
            </Hidden>


            <Hidden only={['md', 'lg', 'xl']}>
                <Drawer open={menuOpen} onClose={() => {
                    setMenuOpen(!menuOpen)
                }}>
                    <Grid container style={{width: '300px', padding: '1em'}}>
                        <Grid item xs={12} justify={'flex-end'}>
                            <div
                                className={`hamburger is-black hamburger--spin ${menuOpen ? 'is-active' : ''}`}
                                onClick={() => setMenuOpen(!menuOpen)}>
                                <div className="hamburger-box">
                                    <div className="hamburger-inner"/>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={'subtitle2'}>Ссылки</Typography>
                            <List component="nav" aria-label="main">
                                <ListItem button>
                                    <ListItemText alignItems={'center'} onClick={() => history.push('/')} >
                                        Карта
                                    </ListItemText>
                                    <ListItemText alignItems={'center'} onClick={() => history.push('/news')} >
                                        Новости
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </Drawer>
            </Hidden>
        </div>
    );
};

export default withRouter(withStyles(appStyle)(Index));