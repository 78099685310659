import React from "react";
import ReactDOM from "react-dom";
import {createBrowserHistory} from "history";
import {Route, Router, Switch} from "react-router-dom";
import indexRoutes from "routes/index.jsx";
import appRoutes from "routes/appRoutes";
import "assets/scss/material-dashboard-pro-react.css?v=1.4.0";
import {ApolloProvider} from '@apollo/react-hooks';
import Client from './graphql/client'
import NotFound from "./views/General/NotFound";
import {register} from './serviceWorker';

const hist = createBrowserHistory();

window.addEventListener(
    "storage",
    function (e) {
        window.location.replace('/pages/login-page')
    },
    false
);


const shouldRedirect = () => {
    return hist.location.pathname.includes('admin') && !localStorage.getItem('email')
}


ReactDOM.render(
    <Router history={hist}>
        <ApolloProvider client={Client}>
            <Switch>


                {!shouldRedirect() ? indexRoutes.map((prop, key) => {
                    return (
                        <Route path={prop.path} component={prop.component} key={key}/>
                    );
                }) : window.location.replace('/pages/login-page')}


                {appRoutes.map((prop, key) => <Route path={prop.path} {...prop.props} component={prop.component}/>
                )}


                <Route component={NotFound}/>

            </Switch>
        </ApolloProvider>
    </Router>,
    document.getElementById("root")
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
register();
