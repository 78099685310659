import React, {useEffect, useState} from 'react';
import { useGetNewsListLazyQuery} from "../../../generated/graphql";
import onError from "../../../misc/graphQLError";
import swal from "sweetalert";
import Pagination from "material-ui-flat-pagination";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Card from "../../../components/Card/Card";
import {parseDate, setPageMeta} from "../../../misc/helpers";
import {CardActions, CardHeader,  Typography} from "@material-ui/core";
import CardBody from "../../../components/Card/CardBody";
import {Button} from "@material-ui/core";
import Search from "./Search";
import Skeleton from '@material-ui/lab/Skeleton';

const theme = createMuiTheme();

const List = (props) => {
    const take = 5;
    const [news, setNews] = useState([])
    const [skip, setSkip] = useState(0)
    const [total, setTotal] = useState(null)


    const [getNews, {loading: dataLoading}] = useGetNewsListLazyQuery({
        fetchPolicy: 'network-only',
        onError: (e) => {
            onError(e.graphQLErrors, () => {
                console.log(e)
                swal('Ошибка', 'Не получилось добавить!', 'error')
            })
        },
        onCompleted: (data) => {

            const { news: payload} = data

            const {totalCount} = payload

            if(totalCount){
                setTotal(totalCount)
            }

            if (payload.data) {
                setNews(payload.data)
            }
        }
    });

    useEffect(() => {
        setPageMeta({
            title: 'Новости',
            description: 'Новости по правонарушениям в Республике Казахстан'
        })
        getNews({
            variables: {
                payload: {
                    pagination: {
                        take,
                        skip
                    }
                },
                fromTable: true
            }
        })
    }, [])

    useEffect(() => {
        if (total) {
            getNews({
                variables: {
                    payload: {
                        pagination: {
                            take,
                            skip
                        }
                    },
                    fromTable: true
                }
            })
        }
    }, [total,skip])

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={'h4'} align={'center'}>Новости</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Search/>
                </Grid>
                {dataLoading ? <React.Fragment>
                    <Grid item xs={12} md={6} lg={4}>
                        <Skeleton  />
                        <Skeleton />
                        <Skeleton variant={'react'} width={'100%'} height={200} style={{marginTop: '1em'}}/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <Skeleton  />
                        <Skeleton />
                        <Skeleton variant={'react'} width={'100%'} height={200} style={{marginTop: '1em'}}/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <Skeleton  />
                        <Skeleton />
                        <Skeleton variant={'react'} width={'100%'} height={200} style={{marginTop: '1em'}}/>
                    </Grid>
                </React.Fragment> : news.map(article => {
                    return <Grid item xs={12} md={6} lg={4}>
                        <Card style={{overflow: 'hidden'}}>
                            <CardHeader title={article.title} subheader={parseDate(article.created_at)}/>
                            <CardBody>
                                <Typography className={'centerImage textContent'} variant={'body1'} dangerouslySetInnerHTML={{
                                    __html: article.shortDescription
                                }}/>
                            </CardBody>
                            <CardActions>
                                <Button round={true} onClick={() => props.history.push(`/news/${article._id}`)} color={"primary"}>
                                    Читать
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                })}
            </Grid>
            <Grid container justify={'center'} alignItems={'center'}>
                <Grid item xs={12} justify={'center'} alignItems={'center'} style={{textAlign: 'center'}}>
                    <MuiThemeProvider theme={theme}>
                        <CssBaseline/>

                        {total ?
                            <Pagination
                                limit={take}
                                offset={skip}
                                total={total}
                                onClick={(e, offset) => setSkip(offset)}
                            /> : null}
                    </MuiThemeProvider>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default List;
