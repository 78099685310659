import React, {useEffect, useState} from 'react'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import moment from 'moment'
import 'moment/locale/ru'

import withStyles from '@material-ui/core/styles/withStyles'

import Fade from '@material-ui/core/Fade';
import appStyle from 'assets/jss/material-dashboard-pro-react/layouts/appStyle.jsx'
import Map from 'views/Events/appMap'
import CircularProgress from '@material-ui/core/CircularProgress'

import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import SnackbarFloating from 'components/Snackbar/SnackbarFloating'
import Snackbar from '@material-ui/core/Snackbar'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/es/CardContent/CardContent'
import Card from '@material-ui/core/Card'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Input from '@material-ui/core/Input'
import PerfectScrollbar from "react-perfect-scrollbar";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import {
    useGetRatingsShortLazyQuery,
    useGetGlobalRatingsShortLazyQuery,
    useGetNewsShortContentLazyQuery, useGetFooterLazyQuery
} from 'generated/graphql'
import onError from "../misc/graphQLError";
import swal from "sweetalert";
import {Hidden, Link} from '@material-ui/core'
import Footer from 'views/Footer/View'
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from '@material-ui/core/Slide';
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Container from "@material-ui/core/Container";
import {setPageMeta} from "../misc/helpers";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

moment.locale('ru')

const events = [{
    lat: 51.1478616,
    lng: 71.3389668,
    region: 'Нур-Султан',
}, {
    lat: 43.2190627,
    lng: 76.7928942,
    region: 'Алматы',
}, {
    lat: 42.341815,
    lng: 69.5547006,
    region: 'Шымкент',
}, {
    lat: 51.9063109,
    lng: 68.6037578,
    region: 'Акмолинская область',
}, {
    lat: 44.7439011,
    lng: 75.9715359,
    region: 'Алматинская область',
}, {
    lat: 43.3095661,
    lng: 68.2357384,
    region: 'Туркестанская область',
}, {
    lat: 49.8241191,
    lng: 73.0285451,
    region: 'Карагандинская область',
}, {
    lat: 51.2182047,
    lng: 51.3159193,
    region: 'Западно-Казахстанская область'
}, {
    lat: 49.9666297,
    lng: 82.5359475,
    region: 'Восточно-Казахстанская область'
}, {
    lat: 54.8739374,
    lng: 69.1253433,
    region: 'Северо-Казахстанская область'
}, {
    lat: 50.2737723,
    lng: 57.123578,
    region: 'Актюбинская область'
}, {
    lat: 52.3166598,
    lng: 76.8945914,
    region: 'Павлодарская область',
}, {
    lat: 53.205533,
    lng: 63.5516156,
    region: 'Костанайская область'
}, {
    lat: 47.6939108,
    lng: 50.5869298,
    region: 'Атырауская область'
}, {
    lat: 43.6595879,
    lng: 51.1756532,
    region: 'Мангистауская область',
}, {
    lat: 44.8281368,
    lng: 65.4699456,
    region: 'Кызылординская область',
}, {
    lat: 42.8961908,
    lng: 71.3337561,
    region: 'Жамбылская область'
}]

const marks = [
    {
        value: 0,
        label: 'Январь',
    },
    {
        value: 1,
        label: 'Февраль',
    },
    {
        value: 2,
        label: 'Март',
    },
    {
        value: 3,
        label: 'Апрель',
    },
    {
        value: 4,
        label: 'Май',
    },
    {
        value: 5,
        label: 'Июнь',
    },
    {
        value: 6,
        label: 'Июль',
    },
    {
        value: 7,
        label: 'Август',
    },
    {
        value: 8,
        label: 'Сентябрь',
    },
    {
        value: 9,
        label: 'Октябрь',
    },
    {
        value: 10,
        label: 'Ноябрь',
    },
    {
        value: 11,
        label: 'Декабрь',
    },
];

const currentMonth = new Date().getMonth()



const MapApp = (props) => {
    const [state, setState] = useState({
        snackbar: false,
        aboutModalIsOpen: false,
        selectedTypes: [],
        selectedYear: 2020,
        selectedRegions: [],
        showStats: true,
        selectedMonth: currentMonth > 0 ? currentMonth - 1 : currentMonth,
        events: events,
        ratings: [],
        globalRating: 0,
        news: [],
        radius: 20,
        loaded: false,
        up: false,
        footer: "",
        points: [],
        left: false,
        menuOpen: false,
    })

    const getFilters = () => {
        let filters = []

        if (typeof state.selectedMonth !== 'undefined' && typeof state.selectedMonth === 'number') {
            filters.push({
                fieldName: 'month',
                value: state.selectedMonth + ''
            })
        }

        if (state.selectedYear) {
            filters.push({
                fieldName: 'year',
                value: state.selectedYear + ''
            })
        }


        if (state.selectedRegions.length > 0) {
            filters.push({
                fieldName: 'region',
                value: state.selectedRegions.join(',')
            })
        }

        if (state.selectedTypes.length > 0) {
            filters.push({
                fieldName: 'type',
                value: state.selectedTypes.join(',')
            })
        }
        return filters
    }

    const [getRatings] = useGetRatingsShortLazyQuery({
        fetchPolicy: 'no-cache',
        onError: (e) => {
            onError(e.graphQLErrors, () => {
                swal('Ошибка', 'Не получилось получить данные!', 'error')
            })
        },
        onCompleted: (data) => {
            const {ratings : payload} = data
            if (payload.data) {
                const ratings = payload.data
                setState({
                    ...state,
                    ratings
                })
            }
        }
    });
    const [getGlobalRatings] = useGetGlobalRatingsShortLazyQuery({
        fetchPolicy: 'no-cache',
        onError: (e) => {
            onError(e.graphQLErrors, () => {
                swal('Ошибка', 'Не получилось получить данные!', 'error')
            })
        },
        onCompleted: (data) => {
            const {globalRatings: payload} = data
            if (payload.data) {
                const lastEl = payload.data[payload.data.length - 1]
                if (lastEl?.rating) {
                    setState({
                        ...state,
                        globalRating: lastEl.rating
                    })
                } else {
                    setState({
                        ...state,
                        globalRating: 0
                    })
                }
            }
        }
    });
    const [getNews] = useGetNewsShortContentLazyQuery({
        fetchPolicy: 'no-cache',
        onError: (e) => {
            onError(e.graphQLErrors, () => {
                swal('Ошибка', 'Не получилось получить данные!', 'error')
            })
        },
        onCompleted: (data) => {
            const {news: payload} = data
            if (payload.data) {
                const news = payload.data
                setState({
                    ...state,
                    news
                })
            }
        }
    });

    const [getFooter] = useGetFooterLazyQuery({
        fetchPolicy: 'no-cache',

        onCompleted: (data) => {
            if (data.footer && data.footer.content) {

                setPageMeta({
                    title: 'Визуальный проект «ИНДЕКС ТРЕВОЖНОСТИ»',
                    description: data.footer.content
                })

                setState({
                    ...state,
                    footer: data.footer.content
                })
            }
        }
    });

    useEffect(() => {
        getFooter()
        initMap()
    }, [])

    useEffect(() => {
        if (state.loaded) {
            getRatings({
                variables: {
                    payload: {
                        filters: [{
                            fieldName: 'year',
                            value: '2020',
                        }, {
                            fieldName: 'month',
                            value: state.selectedMonth + ''
                        }]
                    }
                }
            })

            getGlobalRatings({
                variables: {
                    payload: {
                        filters: [{
                            fieldName: 'year',
                            value: '2020',
                        }, {
                            fieldName: 'month',
                            value: state.selectedMonth + ''
                        }]
                    }
                }
            })
        }
    }, [state.loaded])

    useEffect(() => {
        if (state.loaded) {

            let filters = getFilters()

            getRatings({
                variables: {
                    payload: {
                        filters
                    }
                }
            })

            getGlobalRatings({
                variables: {
                    payload: {
                        filters: filters.filter(fieldName => fieldName !== 'year' || fieldName !== 'month')
                    }
                }
            })


        }
    }, [state.selectedMonth, state.selectedRegions, state.selectedYear])

    useEffect(() => {
        if (state.loaded) {
            getNews({
                variables: {
                    payload: {
                        filters: [{
                            fieldName: 'month',
                            value: state.selectedMonth + ''
                        }, {
                            fieldName: 'year',
                            value: state.selectedYear + ''
                        }]
                    }
                }
            })
        }
    }, [state.selectedMonth, state.selectedYear, state.loaded])

    const initMap = () => {
        setState({
            ...state,
            snackbar: true,
            loaded: true
        })
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setState({
            ...state,
            snackbar: false
        })
    }

    const toggleState = stateKey => {
        if (state.hasOwnProperty(stateKey)) {
            setState({
                ...state,
                [stateKey]: !state[stateKey]
            })
        } else {
            console.warn('Wrong state key provided')
        }
    }


    const {classes} = props

    return (
        <React.Fragment>
            <Card className={classes.appContainer}>
                <section
                    className={[
                        classes.animated,
                        state.up ? classes.moveUpward : ''
                    ].join(' ')}
                >
                    <section>
                        <Hidden only={['md', 'lg', 'xl']}>
                            <AppBar className={classes.appBar} position="fixed">
                                <Toolbar>
                                    <div className={classes.appBar}
                                         style={{display: 'flex', alignItems: 'center', flexDirection: 'row'}}>
                                        <div
                                            className={`hamburger hamburger--spin ${state.menuOpen ? 'is-active' : ''}`}
                                            onClick={() => setState({
                                                ...state,
                                                menuOpen: !state.menuOpen
                                            })}>
                                            <div className="hamburger-box">
                                                <div className="hamburger-inner"/>
                                            </div>
                                        </div>
                                        <Typography style={{flexGrow: 1, textAlign: 'center'}} variant={'subtitle2'}>Индекс
                                            Тревожности</Typography>
                                    </div>
                                </Toolbar>
                            </AppBar>
                        </Hidden>
                        <Hidden only={['sm', 'xs']}>
                            <div className={classes.root}>
                                <AppBar className={classes.appBar} position="fixed">
                                    <Toolbar>
                                        <Container>
                                            <div className={classes.appBarCenter}>
                                                <Typography variant={'subtitle2'}>Индекс Тревожности</Typography>

                                                <div className={classes.ratingContainer}>
                                                    <Typography variant={'subtitle1'}
                                                                align={'center'}>    {state.globalRating > 0 ?
                                                        `Глобальный рейтинг - ${state.globalRating}`
                                                        : ''}</Typography>
                                                </div>
                                                <div className={classes.weatherContainer}>
                                                    <Button onClick={() => setState({
                                                        ...state,
                                                        aboutModalIsOpen: !state.aboutModalIsOpen
                                                    })} color={'default'} style={{color: 'white'}}>О
                                                        проекте</Button>
                                                    <Button color={'default'} onClick={() => props.history.push('/news')} style={{

                                                        color: 'white'
                                                    }}>Новости</Button>
                                                </div>
                                            </div>
                                        </Container>
                                    </Toolbar>
                                </AppBar>
                            </div>
                        </Hidden>

                        <GridContainer justify="space-between">


                            <Hidden only={['sm', 'xs']}>
                                <section
                                    className={[classes.slidingDoor, !state.showStats ? 'clickThrough' : ''].join(' ')}>

                                    <Card
                                        className={[
                                            classes.infoContainer,
                                            !state.showStats ? classes.moveUp : ''
                                        ].join(' ')}
                                    >
                                        <CardContent
                                            className={[classes.padding, classes.positionRelative].join(
                                                ' '
                                            )}
                                        >
                                            <IconButton
                                                onClick={() =>
                                                    setState({
                                                        ...state,
                                                        showStats: !state.showStats
                                                    })
                                                }
                                                className={[
                                                    classes.hideButtonTop,
                                                    classes.button,
                                                    !state.showStats ? classes.down : classes.up
                                                ].join(' ')}
                                                color="inherit"
                                                aria-label="Menu"
                                            >
                                                <ChevronLeftIcon/>
                                            </IconButton>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant={'h5'} align={'center'}>Фильтр
                                                        правонарушений</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <FormControl className={classes.formControl}
                                                                 style={{width: '100%'}}>
                                                        <InputLabel
                                                            id="demo-mutiple-checkbox-label">Регионы</InputLabel>
                                                        <Select
                                                            labelId="demo-mutiple-checkbox-label"
                                                            id="demo-mutiple-checkbox"
                                                            multiple
                                                            value={state.selectedRegions}
                                                            onChange={(value) => {
                                                                setState({
                                                                    ...state,
                                                                    selectedRegions: value.target.value
                                                                })
                                                            }}
                                                            input={<Input/>}
                                                            renderValue={selected => selected.join(', ')}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    style: {
                                                                        maxHeight: 48 * 4.5 + 8,
                                                                        width: 250,
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            {['Нур-Султан',
                                                                'Алматы',
                                                                'Шымкент',
                                                                'Акмолинская область',
                                                                'Алматинская область',
                                                                'Туркестанская область',
                                                                'Карагандинская область',
                                                                'Западно-Казахстанская область',
                                                                'Восточно-Казахстанская область',
                                                                'Северо-Казахстанская область',
                                                                'Актюбинская область',
                                                                'Павлодарская область',
                                                                'Костанайская область',
                                                                'Атырауская область',
                                                                'Мангистауская область',
                                                                'Кызылординская область',
                                                                'Жамбылская область'].map(name => (
                                                                <MenuItem key={name} value={name}>
                                                                    {/*<Checkbox checked={personName.indexOf(name) > -1} />*/}
                                                                    <Checkbox
                                                                        checked={state.selectedRegions.indexOf(name) > -1}/>
                                                                    <ListItemText style={{whiteSpace: 'pre-wrap'}} primary={name}/>
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <FormControl className={classes.formControl}
                                                                 style={{width: '100%'}}>
                                                        <InputLabel id="demo-mutiple-checkbox-label">Типы
                                                            нарушений</InputLabel>
                                                        <Select
                                                            labelId="demo-mutiple-checkbox-label"
                                                            id="demo-mutiple-checkbox"
                                                            multiple
                                                            value={state.selectedTypes}
                                                            onChange={(value) => {
                                                                setState({
                                                                    ...state,
                                                                    selectedTypes: value.target.value
                                                                })
                                                            }}
                                                            input={<Input/>}
                                                            renderValue={selected => selected.join(', ')}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    style: {
                                                                        maxHeight: 48 * 4.5 + 8,
                                                                        width: 250,
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            {[ { label: 'Право на участие в управлении страной', value: 'Право на участие  в управлени истраной'},
                                                                { label: 'Дискриминация', value: 'Дискриминация по этническим,политическим, религиозным,гендерным и иным признакам'},
                                                                { label: 'Свобода совести', value: 'Свобода совести'},
                                                                { label: 'Свобода слова и выражения мнения', value: 'Свобода слова и выражения мнения'},
                                                                { label: 'Свобода мирных собраний', value: 'Свобода мирных собраний'},
                                                                { label: 'Свобода объединений и профсоюзов', value: 'Свобода объединений и профсоюзов'},
                                                                { label: 'Свобода от пыток', value: 'Свобода от пыток'},
                                                                { label: 'Права ребенка', value: 'Права ребенка'},
                                                                { label: 'Права заключенных', value: 'Права заключенных'},
                                                                {label: 'Трудовые права', value: 'Трудовые права'}].map(name => (
                                                                <MenuItem key={name.value} value={name.value}>
                                                                    {/*<Checkbox checked={personName.indexOf(name) > -1} />*/}
                                                                    <Checkbox
                                                                        checked={state.selectedTypes.indexOf(name.value) > -1}/>
                                                                    <ListItemText style={{whiteSpace: 'pre-wrap'}} primary={name.label}/>
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <FormControl className={classes.formControl}
                                                                 style={{width: '100%'}}>
                                                        <InputLabel id="demo-mutiple-checkbox-label">Год</InputLabel>
                                                        <Select
                                                            value={state.selectedYear}
                                                            onChange={(value) => {
                                                                setState({
                                                                    ...state,
                                                                    selectedYear: value.target.value
                                                                })
                                                            }}
                                                            input={<Input/>}
                                                            // renderValue={selected => selected.join(', ')}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    style: {
                                                                        maxHeight: 48 * 4.5 + 8,
                                                                        width: 250,
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            {['2019',
                                                                '2020',
                                                                '2021',
                                                                '2022',
                                                                '2023'].map(name => (
                                                                <MenuItem key={name} value={name}>
                                                                    {/*<Checkbox checked={personName.indexOf(name) > -1} />*/}
                                                                    <ListItemText primary={name}/>
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} md={6} justify={'center'}>
                                                    <FormControl className={classes.formControl}
                                                                 style={{width: '100%'}}>
                                                        <InputLabel id="demo-mutiple-checkbox-label">Месяц</InputLabel>
                                                        <Select
                                                            value={state.selectedMonth}
                                                            onChange={(value) => {
                                                                setState({
                                                                    ...state,
                                                                    selectedMonth: value.target.value
                                                                })
                                                            }}
                                                            input={<Input/>}
                                                            // renderValue={selected => selected.join(', ')}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    style: {
                                                                        maxHeight: 48 * 4.5 + 8,
                                                                        width: 250,
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            {marks.map(name => (
                                                                <MenuItem key={name.value} value={name.value}>
                                                                    {/*<Checkbox checked={personName.indexOf(name) > -1} />*/}
                                                                    <ListItemText primary={name.label}/>
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>

                                            </Grid>


                                        </CardContent>
                                    </Card>
                                </section>
                                <Fade in={state.news.length > 0}>
                                    <div>
                                        {state.news.slice(0, 1).map((article, key) => {
                                            return <Card key={key} className={classes.shortNews}>
                                                <PerfectScrollbar>
                                                    <CardContent  style={{maxHeight: '350px'}}>
                                                        <Typography variant={'h5'}> {article.title}</Typography>
                                                        <div className={'textContent'}>
                                                            <Typography  variant={'body1'} dangerouslySetInnerHTML={{
                                                                __html: article.shortDescription
                                                            }}/>
                                                        </div>

                                                        <Link style={{fontWeight: "bold"}}
                                                              href={'/news/' + article._id}>Подробнее</Link>
                                                    </CardContent>
                                                </PerfectScrollbar>
                                            </Card>
                                        })}
                                    </div>
                                </Fade>
                            </Hidden>


                            <GridItem xs={12}>
                                <Map
                                    isMarkerShown
                                    data={state.events}
                                    filters={getFilters()}
                                    payload={{
                                        selectedMonth: state.selectedMonth,
                                        selectedYear: state.selectedYear,
                                        selectedTypes: state.selectedTypes,
                                        selectedRegions: state.selectedRegions,
                                    }}
                                    ratings={state.ratings}
                                    mapType={state.mapType}
                                    radius={state.radius}
                                    loadingElement={
                                        <div
                                            style={{
                                                display: 'flex',
                                                background: 'white',
                                                width: '100%',
                                                height: '100vh',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <CircularProgress/>
                                        </div>
                                    }
                                    containerElement={<div/>}
                                    mapElement={
                                        <div style={{height: `100vh`, width: '100%'}}/>
                                    }
                                />
                            </GridItem>

                        </GridContainer>
                        <Snackbar
                            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                            open={state.snackbar}
                            autoHideDuration={3000}
                            onClose={handleClose}
                        >
                            <SnackbarFloating
                                onClose={handleClose}
                                variant="success"
                                message="Данные успешно загружены!"
                            />
                        </Snackbar>
                    </section>


                </section>
            </Card>

            <Dialog
                open={state.aboutModalIsOpen}
                TransitionComponent={Transition}
                keepMounted={false}
                onClose={() => setState({
                    ...state,
                    aboutModalIsOpen: !state.aboutModalIsOpen
                })}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title" style={{textAlign: 'center'}}>{"О проекте 'Индекс Тревожности'"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Footer withStyles={false} content={state.footer}/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setState({
                        ...state,
                        aboutModalIsOpen: !state.aboutModalIsOpen
                    })} color="primary">
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>

            <Hidden only={['md', 'lg', 'xl']}>
                <Drawer open={state.menuOpen} onClose={() => {
                    setState({
                        ...state,
                        menuOpen: false
                    })
                }}>
                    <Grid container style={{width: '300px', padding: '1em'}}>
                        <Grid item xs={12} justify={'flex-end'}>
                            <div
                                className={`hamburger is-black hamburger--spin ${state.menuOpen ? 'is-active' : ''}`}
                                onClick={() => setState({
                                    ...state,
                                    menuOpen: !state.menuOpen
                                })}>
                                <div className="hamburger-box">
                                    <div className="hamburger-inner"/>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={'subtitle2'}>Ссылки</Typography>
                            <List component="nav" aria-label="main">
                                <ListItem button>
                                    <ListItemText alignItems={'center'} onClick={() => setState({
                                        ...state,
                                        menuOpen: false,
                                        aboutModalIsOpen: !state.aboutModalIsOpen
                                    })}>
                                        О проекте
                                    </ListItemText>

                                </ListItem>
                                <ListItem button>
                                    <ListItemText alignItems={'center'} onClick={() => props.history.push('/news')}>
                                        Новости
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={'subtitle2'}>Фильтры</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className={classes.formControl} style={{width: '100%'}}>
                                <InputLabel id="demo-mutiple-checkbox-label">Регионы</InputLabel>
                                <Select
                                    labelId="demo-mutiple-checkbox-label"
                                    id="demo-mutiple-checkbox"
                                    multiple
                                    value={state.selectedRegions}
                                    onChange={(value) => {
                                        setState({
                                            ...state,
                                            selectedRegions: value.target.value
                                        })
                                    }}
                                    input={<Input/>}
                                    renderValue={selected => selected.join(', ')}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 48 * 4.5 + 8,
                                                width: 250,
                                            },
                                        },
                                    }}
                                >
                                    {['Нур-Султан',
                                        'Алматы',
                                        'Шымкент',
                                        'Акмолинская область',
                                        'Алматинская область',
                                        'Туркестанская область',
                                        'Карагандинская область',
                                        'Западно-Казахстанская область',
                                        'Восточно-Казахстанская область',
                                        'Северо-Казахстанская область',
                                        'Актюбинская область',
                                        'Павлодарская область',
                                        'Костанайская область',
                                        'Атырауская область',
                                        'Мангистауская область',
                                        'Кызылординская область',
                                        'Жамбылская область'].map(name => (
                                        <MenuItem key={name} value={name}>
                                            {/*<Checkbox checked={personName.indexOf(name) > -1} />*/}
                                            <Checkbox
                                                checked={state.selectedRegions.indexOf(name) > -1}/>
                                            <ListItemText style={{whiteSpace: 'pre-wrap'}} primary={name}/>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className={classes.formControl} style={{width: '100%'}}>
                                <InputLabel id="demo-mutiple-checkbox-label">Типы
                                    нарушений</InputLabel>
                                <Select
                                    labelId="demo-mutiple-checkbox-label"
                                    id="demo-mutiple-checkbox"
                                    multiple
                                    value={state.selectedTypes}
                                    onChange={(value) => {
                                        setState({
                                            ...state,
                                            selectedTypes: value.target.value
                                        })
                                    }}
                                    input={<Input/>}
                                    renderValue={selected => selected.join(', ')}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 48 * 4.5 + 8,
                                                width: 250,
                                            },
                                        },
                                    }}
                                >
                                    {['Право на участие в управлени истраной',
                                        'Дискриминация по этническим,политическим, религиозным,гендерным и иным признакам',
                                        'Свобода совести',
                                        'Свобода слова и выражениямнения',
                                        'Свобода мирных собраний',
                                        'Свобода объединений и профсоюзов',
                                        'Свобода от пыток',
                                        'Права ребенка',
                                        'Права заключенных',
                                        'Трудовые права'].map(name => (
                                        <MenuItem key={name} value={name}>
                                            {/*<Checkbox checked={personName.indexOf(name) > -1} />*/}
                                            <Checkbox
                                                checked={state.selectedTypes.indexOf(name) > -1}/>
                                            <ListItemText  style={{whiteSpace: 'pre-wrap'}} primary={name}/>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className={classes.formControl} style={{width: '100%'}}>
                                <InputLabel id="demo-mutiple-checkbox-label">Год</InputLabel>
                                <Select
                                    value={state.selectedYear}
                                    onChange={(value) => {
                                        setState({
                                            ...state,
                                            selectedYear: value.target.value
                                        })
                                    }}
                                    input={<Input/>}
                                    // renderValue={selected => selected.join(', ')}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 48 * 4.5 + 8,
                                                width: 250,
                                            },
                                        },
                                    }}
                                >
                                    {['2019',
                                        '2020',
                                        '2021',
                                        '2022',
                                        '2023'].map(name => (
                                        <MenuItem key={name} value={name}>
                                            {/*<Checkbox checked={personName.indexOf(name) > -1} />*/}
                                            <ListItemText primary={name}/>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} justify={'center'}>
                            <FormControl className={classes.formControl} style={{width: '100%'}}>
                                <InputLabel id="demo-mutiple-checkbox-label">Месяц</InputLabel>
                                <Select
                                    value={state.selectedMonth}
                                    onChange={(value) => {
                                        setState({
                                            ...state,
                                            selectedMonth: value.target.value
                                        })
                                    }}
                                    input={<Input/>}
                                    // renderValue={selected => selected.join(', ')}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 48 * 4.5 + 8,
                                                width: 250,
                                            },
                                        },
                                    }}
                                >
                                    {marks.map(name => (
                                        <MenuItem key={name.value} value={name.value}>
                                            {/*<Checkbox checked={personName.indexOf(name) > -1} />*/}
                                            <ListItemText primary={name.label}/>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                    </Grid>
                </Drawer>
            </Hidden>

        </React.Fragment>
    )
}

export default withStyles(appStyle)(MapApp)
