const appStyle = theme => ({
  appContainer: {
    overflow: 'hidden',
    position: 'relative',
    height: '100vh',
    transition: '1s'
  },
  animated: {
    height: '100vh',
    transition: '1s'
  },
  moveUpward: {
    transform: 'translateY(-20%)'
  },
  appBar: {
    width: '100%',
    background: '#004eff',
    borderBottomLeftRadius: '32px',
    borderBottomRightRadius: '32px',
    margin: '0 auto'
  },
  sliderContainer: {
    width: '60%',
    position: 'absolute',
    bottom: '5vh',
    right: '20%',
    transition: '1s !important',
  },
  cityStatusContainer: {
    position: 'relative'
  },
  ratingContainer:{
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  weatherContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  weatherStyle:{
    margin: '0 0 0 1em'
  },
  appBarCenter: {
    flexGrow: '1',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  serverStatus: {
    left: '10px',
    top: '50%',
    marginLeft: '-30px',
    marginTop: '-5px',
    position: 'absolute',
    verticalAlign: 'middle',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    '&::before': {
      content: '""',
      animation: 'bounce 1.5s infinite'
    },
    '&::after': {
      content: '""',
      animation: 'bounce 1.5s -0.4s infinite'
    },
    '&::before, &::after': {
      left: '0',
      top: '50%',
      marginLeft: '-1px',
      marginTop: '-6px',
      position: 'absolute',
      verticalAlign: 'middle',
      width: '12px',
      height: '12px',
      borderRadius: '50%'
    },
    '&[type="up"],&[type="up"]::before,&[type="up"]::after': {
      background: '#2ECC71'
    }
  },
  drawer: {
    width: '250px'
  },
  statCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&>p': {
      margin: '0 0 0 5px !important'
    }
  },
  timeScaleContainer: {
    padding: '0 3em !important'
  },
  positionRelative: {
    position: 'relative'
  },
  padding: {
    padding: '24px 24px 48px 24px !important'
  },
  statsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    listStyle: 'none',
    padding: 0,
    margin: 0
  },
  slider: {
    zIndex: 2
  },
  slidingDoor: {
    position: 'absolute',
    top: '86px',
    width: '50%',
    maxWidth: '900px',
    left: '50%',
    overflow: 'hidden !important',
    paddingBottom: '2em',
    transform: 'translate(-50%,-25px)',
    zIndex:12,
    '&.clickThrough': {
      pointerEvents: 'none'
    }
  },
  formControl: {
    width: '150px',
    marginRight: '1em',
    marginBottom: '1em'
  },
  infoContainer: {
    transition: '1s',
    overflow: 'initial !important'
  },
  root: {
    flexGrow: 1
  },
  list: {
    width: '80%',
    margin: '0 auto'
  },
  fullList: {
    width: 'auto'
  },

  button: {
    background: 'white',
    transition: '500ms',
    pointerEvents: 'all',
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
    '&:hover': {
      background: 'white'
    }
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    justifyContent: 'flex-end'
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  hideButton: {
    margin: '0 auto',
    display: 'block',
    transition: '1s',
    top: '-24px'
  },
  hideButtonTop: {
    position: 'absolute',
    top: 'calc(100% - 24px)',
    bottom: 'initial',
    left: 'calc(50% - 24px)',
    transition: '1s',
    background: 'white',
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'
  },
  initialOverflow: {
    overflow: 'initial'
  },
  statIcon: {
    width: '24px',
    height: '24px'
  },
  moveDown: {
    transform: 'translateY(105%) !important'
  },

  moveUp: {
    transform: 'translateY(-75%) !important',
    pointerEvents: 'none'
  },
  time: {
   color: '#004eff',
   fontWeight: '400'
  },


  shortNews: {

    position: 'absolute',
    top: 'calc(50% - 110px)',
    left: '2%',
    zIndex: '11',
    maxWidth: '300px',

    '& ul' : {
      listStyle: 'none',
      padding: '0'
    }
  },

  down: {
    transform: 'rotate(-90deg) '
  },
  up: {
    transform: 'rotate(90deg)'
  }
})

export default appStyle
