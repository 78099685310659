import React from 'react'
import Pages from "layouts/Pages.jsx";
import RDPMap from "layouts/RDPMap.jsx";
import Article from "layouts/Article";
import News from "layouts/News";
import WithYandexAnalytics from "../components/reuse/General/withYandexAnalytics";

const props = {
    exact: true,
    strict: true
}

var appRoutes = [
    {
        path: "/news", name: 'Новости', props: {}, component: (props) =>
            <WithYandexAnalytics>
                <News {...props}/>
            </WithYandexAnalytics>
    },
    {
        path: "/article/:id", name: 'Правонарушение', props: {}, component: (props) =>
            <WithYandexAnalytics>
                <Article {...props}/>
            </WithYandexAnalytics>
    },
    {
        path: "/", name: "Pages", component: (props) =>
            <WithYandexAnalytics>
                <RDPMap {...props}/>
            </WithYandexAnalytics>, props
    },
];

export default appRoutes;
