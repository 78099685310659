import React, {useState, useEffect} from "react";
import * as Yup from "yup";
import {Formik} from "formik";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import {useLoginMutation} from 'generated/graphql'
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import onError from "../../misc/graphQLError";

import Snack from 'components/reuse/Snackbar/Snack'

const LoginSchema = Yup.object().shape({
    password: Yup.string()
        .min(3, "Слишком короткий!")
        .required("Required"),
    email: Yup.string()
        .email("Неправильный email")
        .required("Required")
});

const LoginPage = ({classes }) => {
    const [cardAnimation, setAnimation] = useState('cardHidden')
    const [timeoutFn, setTimeoutFn] = useState(null)
    const [open, setOpen] = useState(false)
    const [login] = useLoginMutation({
        fetchPolicy: 'no-cache', errorPolicy: 'all', onError: (e) => {
            onError(e.graphQLErrors, () => {
                setOpen(true)
            })
        }, onCompleted: data => {
            if (data.login) {
                localStorage.setItem("name", data.login.name ? data.login.name : data.login.email);
                localStorage.setItem('email', data.login.email)
                window.location.replace("/admin/rating");
            }
        }
    });

    useEffect(() => {
        setTimeoutFn(setTimeout(() => {
            setAnimation('')
        }), 700)
        return () => {
            clearTimeout(timeoutFn)
            setTimeoutFn(null)
        }
    }, [])


    return (
        <div className={classes.container}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={6} md={4}>
                        <Formik
                            initialValues={{email: "", password: ""}}
                            validationSchema={LoginSchema}
                            onSubmit={async (values, actions) => {
                                const {email, password} = values;
                                login({
                                    variables: {
                                        email,
                                        password
                                    }
                                })
                                actions.setSubmitting(false);
                            }}
                            render={props => (
                                <form>
                                    <Card login className={classes[cardAnimation]}>
                                        <CardHeader
                                            className={`${classes.cardHeader} ${classes.textCenter}`}
                                            color="rose"
                                        >
                                            <h4 className={classes.cardTitle}>Вход</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <CustomInput
                                                labelText="Email..."
                                                id="email"
                                                name="email"
                                                error={props.errors.email}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Email className={classes.inputAdornmentIcon}/>
                                                        </InputAdornment>
                                                    ),
                                                    onChange: props.handleChange,
                                                    ...props,
                                                    value: props.values.email
                                                }}
                                            />
                                            <CustomInput
                                                labelText="Пароль"
                                                id="password"
                                                name="password"
                                                type={"password"}
                                                error={props.errors.password}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                onChange={props.handleChange}
                                                inputProps={{
                                                    type: "password",
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Icon className={classes.inputAdornmentIcon}>
                                                                lock_outline
                                                            </Icon>
                                                        </InputAdornment>
                                                    ),
                                                    onChange: props.handleChange,
                                                    ...props,
                                                    value: props.values.password
                                                }}
                                            />
                                        </CardBody>
                                        <CardFooter className={classes.justifyContentCenter}>
                                            <Button
                                                onClick={props.submitForm}
                                                disabled={!props.isValid}
                                                color="rose"
                                                simple
                                                size="lg"
                                                block
                                            >
                                                Войти
                                            </Button>
                                        </CardFooter>
                                    </Card>
                                </form>
                            )}
                        />
                    </GridItem>
                </GridContainer>
            <Snack variant={'error'} message={'Неверные данные для входа'} close={() => setOpen(false)} open={open} horizontal={'center'} vertical={'bottom'} />
        </div>
    );
}


export default withStyles(loginPageStyle)(LoginPage);
