import React, {Suspense} from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import {NavLink} from "react-router-dom";
import cx from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
// core components
import HeaderLinks from "components/Header/HeaderLinks.jsx";

import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx";

import avatar from "assets/img/faces/marc.jpg";
import LinearProgress from "@material-ui/core/LinearProgress";
import {List as VirtualList} from "react-virtualized";
import HiddenComponent from '../reuse/Util/PerfectScrollbarHOC'
import helpers from "../../misc/helpers";
import LogoutButton from "./LogoutButton";

const {logout} = helpers;

var ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
    componentDidMount() {
        // if (navigator.platform.indexOf("Win") > -1) {
        ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
            suppressScrollX: true,
            suppressScrollY: false
        });

        // }
    }

    componentWillUnmount() {
        // if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        // }
    }

    render() {
        const {className, user, headerLinks, links} = this.props;
        return (
            <div className={className} ref="sidebarWrapper">
                {user}
                {headerLinks}
                {links}
            </div>
        );
    }
}


class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openAvatar: false,
            openComponents: this.activeRoute("/components"),
            openForms: this.activeRoute("/forms"),
            openTables: this.activeRoute("/tables"),
            openMaps: this.activeRoute("/maps"),
            openPages: this.activeRoute("-page"),
            miniActive: true
        };
        this.activeRoute.bind(this);
    }

    // verifies if routeName is the one active (in browser input)
    activeRoute(routeName) {
        return this.props.location.pathname === routeName;
    }

    openCollapse(collapse) {
        var st = {};
        st[collapse] = !this.state[collapse];
        this.setState(st);
    }

    render() {
        const {
            classes,
            color,
            logo,
            image,
            logoText,
            routes,
            bgColor,
            rtlActive
        } = this.props;
        const itemText =
            classes.itemText +
            " " +
            cx({
                [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
                [classes.itemTextMiniRTL]:
                rtlActive && this.props.miniActive && this.state.miniActive,
                [classes.itemTextRTL]: rtlActive
            });
        const collapseItemText =
            classes.collapseItemText +
            " " +
            cx({
                [classes.collapseItemTextMini]:
                this.props.miniActive && this.state.miniActive,
                [classes.collapseItemTextMiniRTL]:
                rtlActive && this.props.miniActive && this.state.miniActive,
                [classes.collapseItemTextRTL]: rtlActive
            });
        const userWrapperClass =
            classes.user +
            " " +
            cx({
                [classes.whiteAfter]: bgColor === "white"
            });
        const caret =
            classes.caret +
            " " +
            cx({
                [classes.caretRTL]: rtlActive
            });
        const collapseItemMini =
            classes.collapseItemMini +
            " " +
            cx({
                [classes.collapseItemMiniRTL]: rtlActive
            });
        const photo =
            classes.photo +
            " " +
            cx({
                [classes.photoRTL]: rtlActive
            });
        var user = (
            <div className={userWrapperClass}>
                <div className={photo}>
                    <img src={window.localStorage.getItem("email") === "top1@rdmap.kz" ? "/akim.png" : avatar}
                         className={classes.avatarImg} alt="..."/>
                </div>
                <List className={classes.list}>
                    <ListItem className={classes.item + " " + classes.userItem}>
                        <NavLink
                            to={"#"}
                            className={classes.itemLink + " " + classes.userCollapseButton}
                            onClick={() => this.openCollapse("openAvatar")}
                        >
                            <ListItemText
                                primary={rtlActive ? "تانيا أندرو" : localStorage.getItem("name")}
                                secondary={
                                    <b
                                        className={
                                            caret +
                                            " " +
                                            classes.userCaret +
                                            " " +
                                            (this.state.openAvatar ? classes.caretActive : "")
                                        }
                                    />
                                }
                                disableTypography={true}
                                className={itemText + " " + classes.userItemText}
                            />
                        </NavLink>
                        <Collapse in={this.state.openAvatar} unmountOnExit>
                            <List className={classes.list + " " + classes.collapseList}>
                                <ListItem className={classes.collapseItem}>
                                    <NavLink
                                        to="#"
                                        className={
                                            classes.itemLink + " " + classes.userCollapseLinks
                                        }
                                    >
                                        <span className={collapseItemMini}>
                                          {rtlActive ? "و" : "S"}
                                        </span>
                                        <LogoutButton primary={rtlActive ? "إعدادات" : "Выйти"}
                                                      onClick={logout}
                                                      disableTypography={true}
                                                      className={collapseItemText}/>

                                    </NavLink>
                                </ListItem>
                            </List>
                        </Collapse>
                    </ListItem>
                </List>
            </div>
        );

        var links = (
            <List className={classes.list}>
                {!this.props.loadedRoutes ?
                    <ListItem>
                        <LinearProgress style={{width: "100%"}} color={"secondary"}/>
                    </ListItem> : null}
                {routes.map((prop, key) => {

                    if ((prop.lazy && (prop.views.length === 0)) || (prop.redirect || prop.hide)) {
                        return null;
                    }

                    if (prop.collapse) {
                        const navLinkClasses =
                            classes.itemLink +
                            " " +
                            cx({
                                [" " + classes.collapseActive]: this.activeRoute(prop.path)
                            });
                        const itemText =
                            classes.itemText +
                            " " +
                            cx({
                                [classes.itemTextMini]:
                                this.props.miniActive && this.state.miniActive,
                                [classes.itemTextMiniRTL]:
                                rtlActive && this.props.miniActive && this.state.miniActive,
                                [classes.itemTextRTL]: rtlActive
                            });
                        const collapseItemText =
                            classes.collapseItemText +
                            " " +
                            cx({
                                [classes.collapseItemTextMini]:
                                this.props.miniActive && this.state.miniActive,
                                [classes.collapseItemTextMiniRTL]:
                                rtlActive && this.props.miniActive && this.state.miniActive,
                                [classes.collapseItemTextRTL]: rtlActive
                            });
                        const itemIcon =
                            classes.itemIcon +
                            " " +
                            cx({
                                [classes.itemIconRTL]: rtlActive
                            });
                        const caret =
                            classes.caret +
                            " " +
                            cx({
                                [classes.caretRTL]: rtlActive
                            });

                        return (

                            <ListItem key={key} className={classes.item}>
                                <NavLink
                                    to={"#"}
                                    className={navLinkClasses}
                                    onClick={(e) => (e.preventDefault(), this.openCollapse(prop.state))}
                                >
                                    <ListItemIcon className={itemIcon}>

                                        {typeof prop.icon === "string" ? (
                                            <Icon>{prop.icon}</Icon>
                                        ) : (
                                            <prop.icon/>
                                        )}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={prop.name}
                                        secondary={
                                            <b
                                                className={
                                                    caret +
                                                    " " +
                                                    (this.state[prop.state] ? classes.caretActive : "")
                                                }
                                            />
                                        }
                                        disableTypography={true}
                                        className={itemText}
                                    />
                                </NavLink>
                                <Collapse in={this.state[prop.state]} unmountOnExit>
                                    <List className={classes.list + " " + classes.collapseList}>
                                        <HiddenComponent listID={"#here" + key}>
                                            <VirtualList id={"here" + key} width={260}
                                                         height={prop.views.length < 10 ? 50 * prop.views.length : 300}
                                                         rowHeight={50}
                                                         rowRenderer={({index, key, style}) => {
                                                             const el = prop.views[index];
                                                             if (el.redirect) {
                                                                 return null;
                                                             }
                                                             const navLinkClasses =
                                                                 classes.collapseItemLink +
                                                                 " " +
                                                                 cx({
                                                                     [" " + classes[color]]: this.activeRoute(el.path)
                                                                 });
                                                             const collapseItemMini =
                                                                 classes.collapseItemMini +
                                                                 " " +
                                                                 cx({
                                                                     [classes.collapseItemMiniRTL]: rtlActive
                                                                 });
                                                             return (
                                                                 <ListItem key={key} style={style}
                                                                           className={classes.collapseItem}>
                                                                     <NavLink to={el.path} className={navLinkClasses}>
                            <span className={collapseItemMini}>
                              {el.mini}
                            </span>
                                                                         <ListItemText
                                                                             primary={el.name}
                                                                             disableTypography={true}
                                                                             className={collapseItemText}
                                                                         />
                                                                     </NavLink>
                                                                 </ListItem>
                                                             );
                                                         }
                                                         } rowCount={prop.views.length}/>
                                        </HiddenComponent>
                                    </List>
                                </Collapse>
                            </ListItem>
                        );
                    }

                    const navLinkClasses =
                        classes.itemLink +
                        " " +
                        cx({
                            [" " + classes[color]]: this.activeRoute(prop.path)
                        });
                    const itemText =
                        classes.itemText +
                        " " +
                        cx({
                            [classes.itemTextMini]:
                            this.props.miniActive && this.state.miniActive,
                            [classes.itemTextMiniRTL]:
                            rtlActive && this.props.miniActive && this.state.miniActive,
                            [classes.itemTextRTL]: rtlActive
                        });
                    const itemIcon =
                        classes.itemIcon +
                        " " +
                        cx({
                            [classes.itemIconRTL]: rtlActive
                        });
                    return (
                        <ListItem key={key} className={classes.item}>
                            {prop.path.includes("http") ? <a href={prop.path} className={navLinkClasses}>

                                    <ListItemIcon className={itemIcon}>
                                        {typeof prop.icon === "string" ? (
                                            <Icon>{prop.icon}</Icon>
                                        ) : (
                                            <prop.icon/>
                                        )}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={prop.name}
                                        disableTypography={true}
                                        className={itemText}
                                    />
                                </a>
                                : <NavLink to={prop.path} className={navLinkClasses}>
                                    <ListItemIcon className={itemIcon}>
                                        {typeof prop.icon === "string" ? (
                                            <Icon>{prop.icon}</Icon>
                                        ) : (
                                            <Suspense fallback={<CircularProgress/>}>
                                                <prop.icon/>
                                            </Suspense>
                                        )}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={prop.name}
                                        disableTypography={true}
                                        className={itemText}
                                    />
                                </NavLink>}
                        </ListItem>
                    );
                })}

            </List>
        );

        let logoNormal =
            classes.logoNormal +

            " " +
            cx({
                [classes.logoNormalSidebarMini]:
                this.props.miniActive && this.state.miniActive,
                [classes.logoNormalSidebarMiniRTL]:
                rtlActive && this.props.miniActive && this.state.miniActive,
                [classes.logoNormalRTL]: rtlActive
            });
        if (!logo)
            logoNormal += classes.noLogo;

        const logoMini =
            classes.logoMini +
            " " +
            cx({
                [classes.logoMiniRTL]: rtlActive
            });
        const logoClasses =
            classes.logo +
            " " +
            cx({
                [classes.whiteAfter]: bgColor === "white"
            });
        var brand = (
            <div className={logoClasses}>
                {logo ?
                    <a href="#" className={logoMini}>
                        <img src={logo} alt="logo" className={classes.img}/>
                    </a> : null}
                <a href="#" className={logoNormal}>
                    {logoText}
                </a>
            </div>
        );
        const drawerPaper =
            classes.drawerPaper +
            " " +
            cx({
                [classes.drawerPaperMini]:
                this.props.miniActive && this.state.miniActive,
                [classes.drawerPaperRTL]: rtlActive
            });
        const sidebarWrapper =
            classes.sidebarWrapper +
            " " +
            cx({
                [classes.drawerPaperMini]:
                this.props.miniActive && this.state.miniActive,
                [classes.sidebarWrapperWithPerfectScrollbar]:
                navigator.platform.indexOf("Win") > -1
            });
        return (
            <div ref="mainPanel">
                <Hidden mdUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={rtlActive ? "left" : "right"}
                        open={this.props.open}
                        classes={{
                            paper: drawerPaper + " " + classes[bgColor + "Background"]
                        }}
                        onClose={this.props.handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true // Better open performance on mobile.
                        }}
                    >
                        {brand}
                        <SidebarWrapper
                            className={sidebarWrapper}
                            user={user}
                            headerLinks={<HeaderLinks rtlActive={rtlActive}/>}
                            links={links}
                        />
                        {image !== undefined ? (
                            <div
                                className={classes.background}
                                style={{backgroundImage: "url(" + image + ")"}}
                            />
                        ) : null}
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        anchor={rtlActive ? "right" : "left"}
                        variant="permanent"
                        open
                        classes={{
                            paper: drawerPaper + " " + classes[bgColor + "Background"]
                        }}
                    >
                        {brand}
                        <SidebarWrapper
                            className={sidebarWrapper}
                            user={user}
                            links={links}
                        />
                        {image !== undefined ? (
                            <div
                                className={classes.background}

                            />
                        ) : null}
                    </Drawer>
                </Hidden>
            </div>
        );
    }
}

Sidebar.defaultProps = {
    bgColor: "blue"
};

Sidebar.propTypes = {
    classes: PropTypes.object.isRequired,
    bgColor: PropTypes.oneOf(["white", "black", "blue"]),
    rtlActive: PropTypes.bool,
    color: PropTypes.oneOf([
        "white",
        "red",
        "orange",
        "green",
        "blue",
        "purple",
        "rose"
    ]),
    logo: PropTypes.string,
    logoText: PropTypes.string,
    image: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object)
};

export default withStyles(sidebarStyle)(Sidebar);
