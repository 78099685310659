import React from "react";

class SidebarPureComponent extends React.PureComponent {

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.props.mini === nextProps.mini;
  }

  render() {
    return this.props.children;
  }
}

export default SidebarPureComponent;