import React, {useEffect} from 'react';
import {Route, Switch} from "react-router-dom";
import Footer from "views/Footer/View";
import One from "views/NewsPage/components/One";
import List from "views/NewsPage/components/List";
import AppHeader from 'components/AppHeader'
import Container from "@material-ui/core/Container";

const pages = [{
    path: '/news',
    name: 'Новости',
    component: List,
    exact: true
}, {
    path: '/news/:id',
    name: 'Новость',
    component: One,
    exact: false
}]

const NewsIndex = (props) => {


    return (
        <React.Fragment>
            <AppHeader/>
            <Container style={{paddingTop: '100px', minHeight: '90vh', }}>
                <Switch>
                    {pages.map((page, key) => {
                        return <Route key={key} exact={page.exact} path={page.path} component={page.component}/>
                    })}
                </Switch>
            </Container>
            <Footer/>
        </React.Fragment>
    );
};

export default NewsIndex;
