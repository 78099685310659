import React, {useState, useEffect, useRef} from 'react';
import {useGetFooterLazyQuery} from "../../generated/graphql";
import {Typography} from "@material-ui/core";
import styled from 'styled-components'
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

const Wrapper = styled.footer`

    &.withStyles{
        width: 100%;

        padding-top: 2em;
        margin-top: 4em; 
        text-align: center;
        background: #202020;
        
        * {
            color: white !important;
            background: none !important;
       }
    }
    

    
    * {
      font-size: 1em !important;
      background: none !important;
      text-align: center;
      font-family: Montserrat, sans-serif !important;
    }
     
    img {
        display: block;
        margin: 0 auto;
        border-radius: 50px;
     }   
`

const View = ({withStyles = true, content: data = null}) => {

    const [content, setContent] = useState(data)
    const ref = useRef()



    const [getFooter] = useGetFooterLazyQuery({
        fetchPolicy: 'no-cache',

        onCompleted: (data) => {
            if (data.footer && data.footer.content) {
                setContent(data.footer.content)
                console.log(ref.current)
            }
        }
    });

    useEffect(() => {
        if (!content)
            getFooter()
    }, [])

    let sizes = {
        xs: 12
    }

    if (withStyles) {
        sizes.md = 8
        sizes.lg = 6
    }

    return (
        <Wrapper className={withStyles ? 'withStyles' : ''}>
            <Container>
                <Grid container justify={'center'} alignItems={'center'}>
                    <Grid item {...sizes}>
                        {content ? <Typography ref={ref} variant={'body1'} dangerouslySetInnerHTML={{
                            __html: content
                        }}/> : ''}
                    </Grid>

                </Grid>
            </Container>

        </Wrapper>
    );
};

export default View;
