import React from "react";

const themes = [
  {
    primaryBgColor: "#17202E",
    secondahryBgColor: "#202A3B",
    primaryFontColor: "#fff",
    secondaryFontColor: "#8091ab",
    fontSizeHeadLihe: "14px",
    fontSizeParagraph: "32px",
    marginLeft: "30px",
    barChartColor: "#3E91C0",
    barChartColorTwo: "rgb(245, 93, 83)",
    pieChartColor: "#3B6FC3",
    pieChartColorTwo: "#EDF8B1"
  },
  {
    primaryBgColor: "linear-gradient(to bottom left,#216e93,#21648a,#1f5881,#172d5b,#191c51)",
    secondahryBgColor: "#FFFFFF",
    primaryFontColor: "#FFFFFF",
    secondaryFontColor: "#FFFFFF",
    fontSizeHeadLihe: "32px",
    fontSizeParagraph: "17px",
    marginLeft: "15px",
    barChartColor: "#AC3EC2",
    barChartColorTwo: "#93C360",
    pieChartColor: "#6DE2C2",
    pieChartColorTwo: "#F7E53C"
  },
  {
    primaryBgColor: "#F7F7FC",
    secondahryBgColor: "rgba(29,27,65,.6)",
    primaryFontColor: "#363636",
    secondaryFontColor: "#5b90e6",
    fontSizeHeadLihe: "24px",
    fontSizeParagraph: "33.6px",
    marginLeft: "24px",
    barChartColor: "#8BAFEB",
    barChartColorTwo: "#8BAFEB",
    pieChartColor: "#8BAFEB",
    pieChartColorTwo: "#8BAFEB"
  },
  {
    primaryBgColor: "#F8F9FD",
    secondahryBgColor: "#FFFFFF",
    primaryFontColor: "#FFFFFF",
    secondaryFontColor: "#434456",
    fontSizeHeadLihe: "16px",
    fontSizeParagraph: "25px",
    marginLeft: "30px",
    barChartColor: "rgb(93, 98, 181)",
    barChartColorTwo: "rgb(93, 98, 181)",
    pieChartColor: "#68DFF6",
    pieChartColorTwo: "#68DFF6"
  },
  {
    primaryBgColor: "#292929",
    secondahryBgColor: "#3E3E3E",
    primaryFontColor: "#FFFFFF",
    secondaryFontColor: "#FFFFFF",
    fontSizeHeadLihe: "28px",
    fontSizeParagraph: "10px",
    marginLeft: "24px",
    barChartColor: "#C64186",
    barChartColorTwo: "#C64186",
    pieChartColor: "#65CF90",
    pieChartColorTwo: "#65CF90"
  }
];

export default React.createContext(
  'dark'
);

