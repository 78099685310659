import React, {useState, useEffect} from 'react';
import Badge from "@material-ui/core/Badge";
import {useGetArticlesCountBadgeLazyQuery} from "../../../generated/graphql";
import onError from "../../../misc/graphQLError";
import swal from "sweetalert";

const BadgeContainer = ({rating, region, filters, types, isMobile}) => {
    const [value, setValue] = useState('0')

    const [getArticlesCount] = useGetArticlesCountBadgeLazyQuery({
        fetchPolicy: 'no-cache',
        onError: (e) => {
            onError(e.graphQLErrors, () => {
                swal('Ошибка', 'Не получилось получить данные!', 'error')
            })
        },
        onCompleted: (data) => {
            if (data.getArticlesCountBadge) {
                const {count} = data.getArticlesCountBadge
                setValue(`${count}`)
            }
        }
    });


    useEffect(() => {
        getArticlesCount({
            variables: {
                payload: {
                    filters: [
                        ...filters.filter(filter => filter.fieldName !== 'region'),
                        {
                            fieldName: 'region',
                            value: region
                        }
                    ]
                }
            }
        })
    }, [rating, types])


    return (
        <React.Fragment>
            <Badge color={'secondary'}  overlap={'rectangle'} max={999} badgeContent={value} style={{
                position: 'initial !important'
            }}>
                <div className="pin">
                    {rating}
                </div>
            </Badge>
            { !isMobile && <div className="pin-effect"/>}
        </React.Fragment>
    );
};

export default BadgeContainer;
