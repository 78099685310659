import TableIcon from "@material-ui/icons/Tablet";
import helpers from "../../misc/helpers";

const {getValue, authFetch} = helpers;

class RoutesAdapter {

    static getDynamicRoutes = (routes) => {

        return new Promise(async (resolve, reject) => {
            for (let i = 0; i < routes.length; i++) {
                const route = routes[i];
                if (route.lazy) {

                    const isBoss = localStorage.getItem("email") === "arman@dev.com";
                    const id = localStorage.getItem("id");
                    let res;
                    if (!isBoss)
                        res = await authFetch({
                            url: `${route.URL}/userpage/${id}`, callback: (data) => {
                                if (routes[i].lazy && routes[i].dynamicComponent && isBoss) {
                                    data.pages.forEach(innerRoute => {
                                        routes[i].views.push({
                                            lazy: true,
                                            pathTemplate: route.dynamicValueAccessor.pathTemplate,
                                            path: `${route.dynamicValueAccessor.pathPrefix}/${getValue(innerRoute, route.dynamicValueAccessor.pathAccessor)}`,
                                            name: getValue(innerRoute, route.dynamicValueAccessor.nameAccessor),
                                            component: routes[i].dynamicComponent,
                                            boss: innerRoute.visibility,
                                            icon: TableIcon
                                        });
                                    });

                                    routes[i].downloaded = true;
                                } else {
                                    data.pages.forEach(innerRoute => {
                                        routes.push({
                                            path: `${route.dynamicValueAccessor.pathPrefix}/${getValue(innerRoute, route.dynamicValueAccessor.pathAccessor)}`,
                                            pathTemplate: route.dynamicValueAccessor.pathTemplate,
                                            name: getValue(innerRoute, route.dynamicValueAccessor.nameAccessor),
                                            component: routes[i].dynamicComponent,
                                            boss: true,
                                            icon: TableIcon
                                        });
                                    });
                                    routes.splice(i, 1);
                                    routes[i].downloaded = true;
                                }

                            }
                        });
                    else
                        res = await authFetch({
                            url: `${route.URL}`, callback: (data) => {
                                if (routes[i].lazy && routes[i].dynamicComponent && isBoss) {
                                    data.pages.forEach(innerRoute => {
                                        routes[i].views.push({
                                            lazy: true,
                                            pathTemplate: route.dynamicValueAccessor.pathTemplate,
                                            path: `${route.dynamicValueAccessor.pathPrefix}/${getValue(innerRoute, route.dynamicValueAccessor.pathAccessor)}`,
                                            name: getValue(innerRoute, route.dynamicValueAccessor.nameAccessor),
                                            component: routes[i].dynamicComponent,
                                            boss: innerRoute.visibility,
                                            icon: TableIcon
                                        });
                                    });

                                    routes[i].downloaded = true;
                                } else {
                                    data.pages.forEach(innerRoute => {
                                        routes.push({
                                            path: `${route.dynamicValueAccessor.pathPrefix}/${getValue(innerRoute, route.dynamicValueAccessor.pathAccessor)}`,
                                            pathTemplate: route.dynamicValueAccessor.pathTemplate,
                                            name: getValue(innerRoute, route.dynamicValueAccessor.nameAccessor),
                                            component: routes[i].dynamicComponent,
                                            boss: true,
                                            icon: TableIcon
                                        });
                                    });
                                    console.log(routes);
                                    routes.splice(i, 1);
                                    routes[i].downloaded = true;
                                }

                            }
                        });


                }
            }

            resolve(routes);
        });

    };
}

export default RoutesAdapter;
