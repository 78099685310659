import {lazy} from 'react'
import People from "@material-ui/icons/People";
import GavelIcon from '@material-ui/icons/Gavel';
import GradeIcon from '@material-ui/icons/Grade';
import DescriptionIcon from '@material-ui/icons/Description';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Map from "@material-ui/icons/Map";

const Users = lazy(() => import("views/Users"));
const Ratings = lazy(() => import("views/Ratings"));
const GlobalRatings = lazy(() => import("views/GlobalRatings"));
const Articles = lazy(() => import("views/Articles"));
const News = lazy(() => import("views/News"));
const Footer = lazy(() => import("views/Footer"));
const MapComponent = lazy(() => import("layouts/RDPMap"));


const dashRoutes = [
    {
        path: "/admin/users",
        name: "Учетные записи",
        icon: People,
        component: Users,
    },
    {
        path: "/admin/rating",
        name: "Рейтинги",
        icon: GradeIcon,
        component: Ratings,
    },
    {
        path: "/admin/global",
        name: "Общий рейтинг",
        icon: GradeIcon,
        component: GlobalRatings,
    }, {
        path: "/admin/events",
        name: "Правонарушения",
        icon: GavelIcon,
        component: Articles,
    }, {
        path: "/admin/news",
        name: "Новости",
        icon: DescriptionIcon,
        component: News,
    },{
        path: "/admin/footer",
        name: "Футер",
        icon: AttachFileIcon,
        component: Footer,
    },
    {
        path: "/",
        name: "Карта правонарушений",
        icon: Map,
        component: MapComponent,
    },
];
export default dashRoutes;
