import ApolloClient from 'apollo-boost';
import {InMemoryCache} from 'apollo-boost'

const client = new ApolloClient({
    uri: 'https://backend.bureau.kz/graphql',
    fetchOptions: {
        credentials: 'include',
    },
    credentials: 'include',
    addTypename: false,
    cache: new InMemoryCache({
        addTypename: false
    })

});

export default client
