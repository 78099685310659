import React, {useState, useEffect} from 'react';
import {Container, Grid, LinearProgress, Typography} from '@material-ui/core'
import {useGetArticlesFullLazyQuery} from 'generated/graphql'
import ArticleComponent from 'views/Article'
import onError from "../misc/graphQLError";
import swal from "sweetalert";
import Footer from 'views/Footer/View'
import AppHeader from 'components/AppHeader/index'

const Article = (props) => {
    const ArticleID = props.match.params.id
    const [notFound, setNotFound] = useState(false)
    const [loading, setLoading] = useState(true)
    const [article, setArticle] = useState(null)


    const [getArticle] = useGetArticlesFullLazyQuery({
        fetchPolicy: 'no-cache',
        onError: (e) => {
            onError(e.graphQLErrors, () => {
                swal('Ошибка', 'Не получилось получить данные!', 'error')
            })
        },
        onCompleted: (data) => {
            const {articles: payload} = data
            if (payload.data) {
                const [articleItem] = payload.data
                setArticle(articleItem)
                setLoading(false)
            } else {
                setNotFound(true)
                setLoading(false)
            }
        }
    });

    useEffect(() => {
        getArticle({
            variables: {
                payload: {
                    filters: [{
                        fieldName: 'id',
                        value: ArticleID
                    }]
                }
            }
        })
    }, [])

    if (!ArticleID) {

    }
    return (
        <React.Fragment>
            <AppHeader/>
            <Container style={{minHeight: '68vh', paddingTop: '4em'}}>
                <Grid container>
                    {loading ? <LinearProgress style={{width: '100%'}}/> : (!notFound ?
                        <ArticleComponent data={article}/> :
                        <Grid item xs={12} style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100vh'
                        }}>
                            <Typography align={'center'} variant={'h4'}>
                                404. Статья не найдена
                            </Typography>
                        </Grid>)}
                </Grid>

            </Container>
            <Footer/>
        </React.Fragment>
    );
};

export default Article;
