import React, {useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import Chip from '@material-ui/core/Chip';
import { Typography} from "@material-ui/core";
import Card from "../../components/Card/Card";
import Share from "../../components/Share";
import {setPageMeta} from "../../misc/helpers";

const Index = ({data}) => {

    useEffect(() => {
        setPageMeta({
            title: data.title,
            description: data.description
        })
        window.scroll(0, 0);
    }, [])

    return (
        <Grid style={{paddingTop: '2em'}} container justify={'center'} alignItems={'center'}>
            <Card style={{padding: '1em 2em', overflow: 'hidden'}}>
                <Grid container xs={12} justify={'center'} alignItems={'center'}>
                    <Chip
                        style={{marginBottom: '1em'}}
                        label={data.type}
                        color="primary"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography align={'center'} variant={'h4'}>{data.title}</Typography>
                </Grid>


                <Grid item xs={12}>
                    <Typography align={'center'} variant={'body2'} style={{marginTop: '1em'}}>
                        Статья написана {new Date(Number.parseInt(data.created_at)).toLocaleString('ru-RU')}
                    </Typography>
                    <Grid container direction={'column'} alignItems={'center'} justify={'center'} style={{marginBottom: '2em'}}>
                        <Typography variant={'caption'}  style={{marginBottom: '.5em', marginTop: '1em'}}>Поделиться</Typography>
                        <Share/>
                    </Grid>
                    <Typography variant={'body1'} className={'textContent'} dangerouslySetInnerHTML={{
                        __html: data.description
                    }}/>
                </Grid>
            </Card>

        </Grid>
    );
};

export default Index;
