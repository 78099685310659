import React from 'react';
import {useLogoutMutation} from "../../generated/graphql";
import onError from "../../misc/graphQLError";
import ListItemText from "@material-ui/core/ListItemText";

const LogoutButton = (props) => {
    const [logout] = useLogoutMutation({
        fetchPolicy: 'no-cache', errorPolicy: 'all', onError: (e) => {
            debugger
            onError(e.graphQLErrors, () => {
            })
        }, onCompleted: data => {
            localStorage.removeItem("name");
            localStorage.removeItem('email')
            localStorage.removeItem('role')
            window.location.replace("/pages/login-page");
        }
    });
    return (
        <ListItemText
            {...props}
            onClick={() => logout()}
        />
    );
};

export default LogoutButton;