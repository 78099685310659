import React from "react";
import PerfectScrollbar from "perfect-scrollbar";

const PerfectScrollbarHOC = ({ children, listID }) => {
    let [ps, setPS] = React.useState(null);

    React.useEffect(() => {

      if (listID) {
        if (!ps) {
          const element = document.querySelector(listID);
          if (element.className.indexOf("ps") == -1) {
            setPS(new PerfectScrollbar(document.querySelector(listID), {}));
          }
        }
        return () => {
          if (ps)
            ps.destroy();
        };
      } else {
        console.warn("NO ID IS SET TO THE SCROLLBAR");
      }
    }, []);

    return children;
  }
;

export default PerfectScrollbarHOC;