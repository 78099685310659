
const getValue = (obj, property) => {
  if (property) {
    const destructuredProperty = property.split(".");
    let s = obj[destructuredProperty[0]];
    return getValue(s, destructuredProperty.slice(1).join("."));
  } else {
    return obj;
  }
};

export const   parseDate = (date) => {
  const value = typeof date === "string" ? Number.parseInt(date) :date
  return new Date(value).toLocaleString('ru-RU')
}

export const setPageMeta = ({title, description}) => {

  if(description) {
    const footerTemp = document.createElement('p')
    footerTemp.setAttribute('id', 'tempFooter')
    footerTemp.innerHTML = description

    let metaDescription = document.querySelector('meta[name="description"]')
    if(metaDescription){
      metaDescription.innerText = footerTemp.innerText.slice(0,100).trim()
    } else {
      metaDescription = document.createElement('meta')
      metaDescription.setAttribute('name', 'description')
      metaDescription.innerText = footerTemp.innerText.slice(0,100).trim()
      document.head.appendChild(metaDescription)
    }



  }

  if (title) {
    const metaTitle = document.querySelector('title')
    metaTitle.innerText = title

    let ogTitle = document.querySelector('meta[property="og:title"]')
    if(ogTitle){
      ogTitle.innerText = title
    } else {
      ogTitle = document.createElement('meta')
      ogTitle.setAttribute('property', 'og:title')
      ogTitle.innerText = title
      document.head.appendChild(ogTitle)
    }

  }


}

export default {
  to: promise => promise.then(data => [null, data]).catch(err => [err.message]),
  syncImageUpload: (method, url, image, updateUploadStatus) => {

    return new Promise(function(resolve, reject) {
      let xhr = new XMLHttpRequest();
      xhr.open(method, url);
      xhr.onload = function() {
        if (this.status >= 200 && this.status < 300) {
          resolve(xhr.response);
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.withCredentials = true;

      xhr.upload.addEventListener("progress", e => {
        console.log(e);
        updateUploadStatus(parseInt(e.loaded / e.total * 100));
      });
      xhr.onerror = function() {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      };

      let formData = new FormData();
      formData.append("file", image);

      xhr.send(formData);
    });
  },
  authFetch: ({ url, options = {}, callback, onError = () => {}, blob = false }) => {
    let fetchOptions = {
      ...options,
      credentials: "include"
    };
    if (options.method !== "POST") {
      delete fetchOptions.body;
    }
    return fetch(url, fetchOptions).then(res => {
      if (res.status === 401 || res.status === 403) {
        localStorage.removeItem("email");
        localStorage.removeItem("name");
        window.location.replace("/pages/login-page");
      } else {
        if(blob)
          return res.blob()
        else
          return res.json();
      }
    }).then(data => {
      callback(data);
    }).catch( e => onError(e));
  },
  logout: () => {
    fetch(`${process.env.REACT_APP_HOST}/user/logout`, {
      method: "POST",
      credentials: "include"
    }).then(res => {
      if (res.status === 200) {
        localStorage.removeItem("email");
        localStorage.removeItem("name");
        window.location.replace("/pages/login-page");
      }
    }).catch( e => console.log(e));
  },
  post: (url = ``, data = {}) => {
    // Default options are marked with *
    return fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json"
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
      .then(response => response.json()); // parses response to JSON
  },
  // async function
  fetchAsync: async (path) => {
    // await response of fetch call
    let response = await fetch(path, {
      credentials: "include"
    });
    // only proceed once promise is resolved
    let data = await response.json();
    // only proceed once second promise is resolved
    return data;
  },
  rus_to_latin: (str) => {

    const ru = {
      "а": "a", "б": "b", "в": "v", "г": "g", "д": "d",
      "е": "e", "ё": "e", "ж": "j", "з": "z", "и": "i",
      "к": "k", "л": "l", "м": "m", "н": "n", "о": "o",
      "п": "p", "р": "r", "с": "s", "т": "t", "у": "u",
      "ф": "f", "х": "h", "ц": "c", "ч": "ch", "ш": "sh",
      "щ": "shch", "ы": "y", "э": "e", "ю": "u", "я": "ya"
    }, n_str = [];

    str = str.replace(/[ъь]+/g, "").replace(/й/g, "i");

    for (var i = 0; i < str.length; ++i) {
      n_str.push(
        ru[str[i]]
        || ru[str[i].toLowerCase()] == undefined && str[i]
        || ru[str[i].toLowerCase()].replace(/^(.)/, function(match) {
          return match.toUpperCase();
        })
      );
    }

    return n_str.join("");
  },
  getValue: (obj, property) => {
    if (property) {
      const destructuredProperty = property.split(".");
      let s = obj[destructuredProperty[0]];
      return getValue(s, destructuredProperty.slice(1).join("."));
    } else {
      return obj;
    }
  },


};
