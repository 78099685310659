import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import {Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import {withRouter} from 'react-router-dom'

const theme = createMuiTheme();

class PaginationContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {offset: 0};
    }

    handleClick(offset) {
        this.setState({offset});
    }

    render() {
        return (<Grid container justify={'center'} alignItems={"center"} style={{textAlign: 'center'}}>
                <Grid item xs={12}>
                    <Typography variant={'subtitle2'} style={{marginBottom: '.5em'}}>Правонарушения</Typography>
                </Grid>
                <Grid item xs={12} styles={{paddingBottom: '1em'}}>
                    <List component="nav" aria-label="main" >
                        {this.props.data.slice(this.state.offset, this.state.offset + 4).map(el => {
                            return <ListItem alignItems={'center'} button onClick={() => {
                                this.props.history.push(`/article/${el._id}`)
                            }}>
                                <ListItemText primary={el.title} secondary={el.type}/>
                            </ListItem>
                        })}
                    </List>

                </Grid>
                {this.props.data.length > 4 && <Grid item xs={12}>
                    <MuiThemeProvider theme={theme}>
                        <CssBaseline/>
                        <Pagination
                            limit={4}
                            offset={this.state.offset}
                            total={this.props.data.length}
                            onClick={(e, offset) => this.handleClick(offset)}
                        />
                    </MuiThemeProvider>
                </Grid>}
            </Grid>
        );
    }
}

export default withRouter(PaginationContainer)