import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {LinearProgress, Typography} from "@material-ui/core";
import Card from "components/Card/Card";
import Share from "components/Share";
import {useGetNewsFullContentLazyQuery} from "../../../generated/graphql";
import onError from "../../../misc/graphQLError";
import swal from "sweetalert";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import {setPageMeta} from "../../../misc/helpers";


const One = (props) => {
    const newsID = props.match.params.id
    const [notFound, setNotFound] = useState(false)
    const [loading, setLoading] = useState(true)
    const [article, setArticle] = useState(null)


    useEffect(() => {
        window.scroll(0, 0);
    }, [])


    const [getArticle] = useGetNewsFullContentLazyQuery({
        fetchPolicy: 'no-cache',
        onError: (e) => {
            onError(e.graphQLErrors, () => {
                swal('Ошибка', 'Не получилось получить данные!', 'error')
            })
        },
        onCompleted: (data) => {
            const {news: payload} = data
            if (payload.data) {
                const [articleItem] = payload.data
                setArticle(articleItem)
                setPageMeta({
                    title: articleItem.title,
                    description: articleItem.description
                })
                setLoading(false)
            } else {
                setNotFound(true)
                setLoading(false)
            }
        }
    });

    useEffect(() => {
        getArticle({
            variables: {
                payload: {
                    filters: [{
                        fieldName: 'id',
                        value: newsID
                    }]
                }
            }
        })
    }, [])

    return (
        <Grid style={{paddingTop: '2em'}} container justify={'center'} alignItems={'center'}>
            <Card style={{padding: '1em 2em', overflow: 'hidden'}}>
                {loading ? <LinearProgress style={{width: '100%'}}/> : <React.Fragment>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="/news">
                            Новости
                        </Link>
                        <Link color="inherit">
                            {article.title}
                        </Link>
                    </Breadcrumbs>
                    <Grid item xs={12}>
                        <Typography align={'center'} variant={'h4'}>{article.title}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography align={'center'} variant={'body2'} style={{marginTop: '1em'}}>
                            Новость написана {new Date(Number.parseInt(article.created_at)).toLocaleString('ru-RU')}
                        </Typography>
                        <Grid container direction={'column'} alignItems={'center'} justify={'center'}
                              style={{marginBottom: '2em'}}>
                            <Typography variant={'caption'}
                                        style={{marginBottom: '.5em', marginTop: '1em'}}>Поделиться</Typography>
                            <Share/>
                        </Grid>
                        <Typography variant={'body1'} className={'textContent'} dangerouslySetInnerHTML={{
                            __html: article.description
                        }}/>
                    </Grid>
                </React.Fragment>}
            </Card>

        </Grid>
    );
};

export default One;
