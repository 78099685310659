import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Hidden from '@material-ui/core/Hidden'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

// @material-ui/icons
import Menu from '@material-ui/icons/Menu'

// core components
import Button from 'components/CustomButtons/Button'
import MButton from '@material-ui/core/Button'

import pagesRoutes from 'routes/pages.jsx'

import pagesHeaderStyle from 'assets/jss/material-dashboard-pro-react/components/pagesHeaderStyle.jsx'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class PagesHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      openModal: false
    }
  }

  handleDrawerToggle = () => {
    this.setState({ open: !this.state.open })
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.setState({ open: false })
    }
  }

  render() {
    const { classes, color } = this.props
    const appBarClasses = cx({
      [' ' + classes[color]]: color
    })

    var list = (
      <React.Fragment >

        <List className={classes.list} >
          <ListItem className={[classes.listItem,classes.navLink, classes.listItemModal].join(' ')} >
            <ListItemText
              primary={'О проекте'}
              onClick={() => this.setState({ openModal: true})}
              disableTypography={true}
              className={classes.listItemText}
            />
          </ListItem >
          {pagesRoutes.map((prop, key) => {
            if (prop.redirect) {
              return null
            }
            const navLink =
                    classes.navLink +
                    cx({
                      [' ' + classes.navLinkActive]: this.activeRoute(prop.path)
                    })
            return (
              <ListItem key={key} className={classes.listItem} >
                <NavLink to={prop.path} className={navLink} >
                  <ListItemIcon className={classes.listItemIcon} >
                    <prop.icon />
                  </ListItemIcon >
                  <ListItemText
                    primary={prop.short}
                    disableTypography={true}
                    className={classes.listItemText}
                  />
                </NavLink >
              </ListItem >
            )
          })}
        </List >
      </React.Fragment >
    )
    return (
      <React.Fragment>
      <AppBar position="static" className={classes.appBar + appBarClasses} >
        <Toolbar className={classes.container} >
          <Hidden smDown >
            <div className={classes.flex} >
              <Button href="#" className={classes.title} color="transparent" >
                Excelsior
              </Button >
            </div >
          </Hidden >
          <Hidden mdUp >
            <div className={classes.flex} >
              <Button href="#" className={classes.title} color="transparent" >
                Excelsior
              </Button >
            </div >
          </Hidden >
          <Hidden smDown >{list}</Hidden >
          <Hidden mdUp >
            <Button
              className={classes.sidebarButton}
              color="transparent"
              justIcon
              aria-label="open drawer"
              onClick={this.handleDrawerToggle}
            >
              <Menu />
            </Button >
          </Hidden >
          <Hidden mdUp >
            <Hidden mdUp >
              <Drawer
                variant="temporary"
                anchor={'right'}
                open={this.state.open}
                classes={{
                  paper: classes.drawerPaper
                }}
                onClose={this.handleDrawerToggle}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
              >
                {list}
              </Drawer >
            </Hidden >
          </Hidden >
        </Toolbar >
      </AppBar >

        <Dialog
          open={this.state.openModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.setState({ openModal: false})}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{"Знакомство"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Информация о проекте
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MButton onClick={() => this.setState({openModal: false})}   color="primary">
              Закрыть
            </MButton>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

PagesHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger'])
}

export default withStyles(pagesHeaderStyle)(PagesHeader)
